<template>
  <div class="user">
    <SidebarComponent />
    <div class="content">
      <div class="search-bar">  
        <div class="search-group">  
          <div class="custom-input">  
            <img src="@/assets/search.png" alt="Search" class="icon" />  
            <input  
              type="text"  
              v-model="searchQuery"  
              placeholder="请输入用户名"
              @keyup.enter="handleSearch"  
            />  
          </div>  
          <button class="search-button" @click="handleSearch">搜索</button>  
        </div>  
      </div> 

      <!-- 用户列表 -->
      <div class="user-list">
          <div v-for="user in users" :key="user.id" class="user-item">
              <UserInfo 
                  :userinfo="user" 
                  @user-deleted="handleUserDeleted"   
              /> 
          </div>
      </div>


      <!-- 分页组件 -->
      <div class="pagination-container">  
        <el-pagination  
          @current-change="handlePageChange"  
          :current-page="currentPage"  
          :page-size="pageSize"  
          :total="totalUsers"  
          layout="prev, pager, next, jumper"  
          :pager-count="5"  
        />  
      </div>  
    </div>
  </div>
</template>

<script>
import SidebarComponent from "@/components/sidebar.vue"; 
import UserInfo from "@/components/userinfo.vue"; 
import { ElPagination } from 'element-plus';
import { getUserListAll, getUser } from "@/utils/api/user.js";

export default {
  name: "UserPage",
  components: {
    SidebarComponent,
    UserInfo,
    ElPagination
  },
  data() {  
    return {  
      searchQuery: '',  
      currentPage: 1,  
      pageSize: 9,  
      totalUsers: 0,  
      users: []  
    };  
  },
  methods: {

      async fetchUsers() {
      try {
        console.log("请求页码:", this.currentPage, "页面大小:", this.pageSize);
        const response = await getUserListAll(this.currentPage, this.pageSize);
        console.log("接口响应:", response);

        if (response.data && response.data.rows) {
          this.users = response.data.rows;
          this.totalUsers = Number(response.data.total);
        } else {
          this.users = [];
          this.totalUsers = 0;
        }
      } catch (error) {
        console.error("获取用户数据失败:", error);
        this.users = [];
        this.totalUsers = 0;
      }
    },

    searchUsers() {
      if (!this.searchQuery.trim()) {
        console.warn('搜索查询为空');
        return;
      }

      getUser(this.currentPage, this.pageSize, this.searchQuery)
        .then(response => {
          if (response.code) {
            this.users = response.data.rows;
            this.totalUsers = Number(response.data.total);
          } else {
            console.error('获取用户数据失败:', response.msg);
          }
        })
        .catch(error => {
          console.error('查询用户失败:', error);
        });
    },

        handleSearch() {
      this.currentPage = 1; // 重置为第一页
      if (!this.searchQuery.trim()) {
        // 如果搜索框为空，展示所有用户
        this.fetchUsers();
      } else {
        // 如果搜索框有内容，执行搜索
        this.searchUsers();
      }
    },
    
    handlePageChange(page) {
      this.currentPage = page; // 更新当前页
      this.fetchUsers(); // 重新获取用户列表
    },


    handleUserDeleted(userId) {
      this.users = this.users.filter(user => user.id !== userId);
      this.totalUsers--;  // 更新总用户数量
      this.fetchUsers();   // 重新拉取最新用户列表
    }

  },
  mounted() {
    this.fetchUsers();  
  },
};
</script>



<style scoped>
.user {
  display: flex;
  background-color: #f7f7f7;
  height: 100vh;
}

.content {
  flex: 1;
  padding: 10px 20px;
  background-color: #f7f7f7; 
  height: 100vh; 
  margin-top: 115px;
  margin-left: 200px;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  margin-bottom: 20px;
}

.search-group {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 5px;
  width: 20px; 
  height: 20px; 
}

.custom-input {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px;
  flex-grow: 1;
  width: 300px;
}

.custom-input input {
  border: none;
  outline: none;
  flex-grow: 1;
}

.search-button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #ffd400;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.user-list {
  display: flex;
  flex-wrap: wrap;
  gap:20px; /* 设置间距 */
}

.user-item {
  width: calc(33.33% - 20px); 
  margin-bottom: 20px;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
</style>
