<template>
  <div class="home">
    <SidebarComponent />
    <div class="main-content">
      <div class="admin-header">
        <div class="admin-info">
          <img src="@/assets/user_icon.png" alt="user-icon" class="avatar" />
          <div class="role-container">
            <span class="small-role">欢迎回来！</span>
            <span class="role">管理员</span>
          </div>
        </div>
        <button class="logout-button" @click="logout">
          <i class="logout-icon"></i>
          <span>退出登录</span>
        </button>
      </div>

      <div class="admin-bottom">
        <header class="bottom-header">
          <span class="title">管理</span>
          <hr class="divider" />
          <!-- <div class="icon-grid">
          <div class="icon-item" v-for="item in menuItems" :key="item.name">
            <div :class="['icon-circle', item.background]">
              <img :src="item.icon" :alt="item.label" class="icon" />
            </div>
            <span class="label">{{ item.label }}</span>
          </div>
        </div> -->
          <div class="icon-grid">
            <div
              class="icon-item"
              v-for="item in menuItems"
              :key="item.name"
              @click="navigateTo(item.route)"
            >
              <div :class="['icon-circle', item.background]">
                <img :src="item.icon" :alt="item.label" class="icon" />
              </div>
              <span class="label">{{ item.label }}</span>
            </div>
          </div>
        </header>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarComponent from "@/components/sidebar.vue";
import { useRouter } from "vue-router";

export default {
  name: "HomePage",
  components: {
    SidebarComponent,
  },
  setup() {
    const router = useRouter(); // 使用 useRouter 获取 router 实例

    const getMenuItems = () => {
      return [
        {
          name: "recipe",
          label: "菜谱管理",
          icon: require("@/assets/icon_book_white.png"),
          background: "bg-yellow",
          route: "/recipe",
        },
        {
          name: "cuisine",
          label: "美食管理",
          icon: require("@/assets/icon_food_white.png"),
          background: "bg-green",
          route: "/cuisine",
        },
        {
          name: "user",
          label: "用户管理",
          icon: require("@/assets/icon_user_white.png"),
          background: "bg-red",
          route: "/user",
        },
        {
          name: "map",
          label: "美食地图",
          icon: require("@/assets/icon_map_white.png"),
          background: "bg-blue",
          route: "/map",
        },
        {
          name: "analysis",
          label: "数据分析",
          icon: require("@/assets/icon_chart_white.png"),
          background: "bg-orange",
          route: "/analysis",
        },
      ];
    };

    return {
      menuItems: getMenuItems(),
      logout() {
        console.log("Logging out...");
        localStorage.removeItem("token");
        router.push("/login");
      },
      navigateTo(route) {
        // 新增跳转方法
        router.push(route); // 使用 router 进行页面跳转
      },
    };
  },
};
</script>

<style scoped>
.home {
  display: flex;
}

.main-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 120px;
  margin-left: 210px;
  /* 使主内容区域留出侧边栏的宽度 */
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 205px;
  background-color: #ffffff;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.admin-info {
  display: flex;
  align-items: center;
  margin-left: 40px;
  gap: 15px;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.role-container {
  display: flex;
  flex-direction: column;
  /* 垂直方向排列 */
  align-items: flex-start;
  /* 左对齐 */
}

.role {
  font-size: 22px;
  color: #3b3b3b;
  margin-top: 5px;
  /* 设置与上方文本的间距 */
}

.small-role {
  font-size: 14px;
  color: #888888;
}

.logout-button {
  background: none;
  border: none;
  color: #666666;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 40px;
  margin-top: 10px;
}

.logout-icon {
  width: 20px;
  height: 20px;
  background-image: url("@/assets/logout_icon.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.admin-bottom {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  height: 400px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.bottom-header {
  width: 100%;
  text-align: left;
  /* padding: 0 20px; */
  margin-top: 20px;
  /* margin-left: 20px;  */
  height: 40px;
}

.title {
  font-size: 18px;
  color: #333;
  margin-left: 20px;
}

.divider {
  width: 100%;
  border: none;
  border-top: 1px solid #eee;
  margin: 25px 0px;
}

.icon-grid {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 45px;
  margin-left: -20px;
}

.icon-item {
  text-align: center;
  margin-top: 40px;
  margin-left: 40px;
  cursor: pointer;
}

.icon-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.icon {
  width: 50px;
  height: 50px;
}

.label {
  font-size: 14px;
  color: #575757;
}

.bg-yellow {
  background-color: #ffcc00;
}

.bg-green {
  background-color: #a8e6cf;
}

.bg-red {
  background-color: #ff8b94;
}

.bg-blue {
  background-color: #84caff;
}

.bg-orange {
  background-color: #ff9e80;
}
</style>
