<template>
  <div class="Delete-box">
    <div class="Delete-title">提示</div>
    <div class="Delete-content">
      {{ getMessage }}
      <div class="button-container">
        <button class="Cancel-btn" @click="handleCancel">取消</button>
        <button class="Confirm-btn" @click="handleConfirm">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteUserById } from "@/utils/api/user.js";
import { deleteCuisine } from "@/utils/api/cuisine.js";
import { deleteRecipe } from "@/utils/api/recipe.js";

export default {
  name: "DeleteConfirmation",
  props: {
    message: {
      type: String,
      default: "确定要删除吗？",
    },
    deleteType: {
      type: Number,
      default: 1,
    },
    itemId: {
      type: Number,
      required: true, // 确保父组件传入了 itemId
    },
    // token: {
    //     type: String,
    //     required: true // 确保父组件传入了 token
    // }
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    handleConfirm() {
      switch (this.deleteType) {
        case 1:
          this.deleteUser(this.itemId);
          break;
        case 2:
          this.deleteCuisine(this.itemId);
          break;
        case 3:
          this.deleteRecipe(this.itemId);
          break;
        default:
          this.$emit("confirm");
      }
    },
    deleteUser(id) {
      deleteUserById(id)
        .then(() => {
          // 处理删除成功的逻辑
          this.name = "";
          this.imgUrl = "";
          this.id = "";
          this.showDeleteBox = false;
          this.$emit("confirm");
        })
        .catch((error) => {
          // 处理错误情况
          console.error("删除用户失败:", error);
        });
    },
    deleteCuisine(id) {
      deleteCuisine(id)
        .then((response) => {
          // if (response.code === 200) {
          if (response.code) {
            this.coverUrl = "";
            this.dislikeCount = "";
            this.id = "";
            this.likeCount = "";
            this.location = "";
            this.name = "";
            this.showDeleteBox = false;
            this.$emit("confirm", response.code);
            console.log("删除美食返回:" + response.msg + response.code);
          } else {
            console.error("删除美食失败:", response.msg);
          }
        })
        .catch((error) => {
          console.error("删除美食失败:", error);
        });
    },
    deleteRecipe(id) {
      deleteRecipe(id)
        .then((response) => {
          console.log(id,typeof id);
          console.log(response);
          if (response.code === 200) {
            this.coverUrl = "";
            this.collectCount = "";
            this.id = "";
            this.likeCount = "";
            this.dislikeCount = "";
            this.name = "";
            this.isSystemRecipe = "";
            this.showDeleteBox = false;
            this.$emit("confirm", response.code);
            // this.$router.push('/recipe');
            console.error("删除菜谱成功:", response.msg);
            // this.$router.go(0); 
            // 刷新页面
          } else {
            console.error("删除菜谱失败:", response.msg);
          }
        })
        .catch((error) => {
          console.error("删除菜谱失败:", error);
        });
    },
  },
  computed: {
    getMessage() {
      const messages = {
        1: "确定删除此用户信息吗？",
        2: "确定删除该美食信息吗？",
        3: "确定删除该菜谱信息吗？",
      };
      return messages[this.deleteType] || this.message;
    },
  },
};
</script>

<style scoped>
.Delete-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 25px;
  width: 280px;
  height: 170px;
  background-color: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  z-index: 1001;
  /* 确保弹框在遮罩层上面 */
}

.Delete-title {
  font-size: 16px;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  margin-bottom: 20px;
  text-align: left;
  color: #575757;
  border-bottom: 1.5px solid #ededed;
}

.Delete-content {
  font-size: 20px;
  margin-top: 5px;
  padding-top: 5px;
  position: relative;
  top: 5px;
  color: #575757;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  margin-top: 30px;
}

.Cancel-btn {
  padding: 4px 25px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 18px;
  margin-right: 30px;
  color: #575757;
  background-color: #d9d9d9;
  cursor: pointer;
}

.Confirm-btn {
  padding: 4px 25px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 18px;
  margin-left: 30px;
  color: #575757;
  background-color: #fed622;
  cursor: pointer;
}
</style>
