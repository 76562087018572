// api/user.js
import request from "@/utils/request";

// 查询用户列表
export function getUserListAll(pageNum, pageSize) {
  return request({
    url: "/admin/user/getByName",
    method: "post",
    data: {
      pageNum, // 页码
      pageSize, // 一页几个
    },
  });
}

// 查询菜谱
export function getUser(pageNum, pageSize, userName) {
  return request({
    url: "/admin/user/getByName",
    method: "post",
    data: {
      pageNum, // integer
      pageSize, // integer
    },
    params: {
      userName, // string
    },
  });
}

// 删除特定用户
export function deleteUserById(id) {
  return request({
    url: `/admin/user/${id}`, // 接口地址， integer
    method: "delete", // 请求方法
  });
}
