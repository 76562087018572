<template>
  <div class="cuisine-info" v-if="showCuisine">
    <div class="id">美食</div>
    <div class="divider"></div>
    <div class="content">
      <img :src="cuisineinfo.coverUrl" class="image" alt="img" />
      <div class="states">
        <div class="like-state">
          <div class="state-title">点赞数</div>
          <img src="@/assets/like.png" alt="like" class="icon" />
          <div class="count">{{ cuisineinfo.likeCount }}</div>
        </div>
        <div class="dislike-state">
          <div class="state-title">点踩数</div>
          <img src="@/assets/dislike3.png" alt="dislike" class="dislike-icon" />
          <div class="count">{{ cuisineinfo.dislikeCount }}</div>
        </div>
      </div>
    </div>
    <div class="imformation">
      <div
        class="cuisine-name"
        @mouseover="checkOverflow1"
        @mouseleave="showTooltip1 = false"
      >
        名称：{{ cuisineinfo.name }}
      </div>
      <div v-if="showTooltip1" class="tooltip1">{{ cuisineinfo.name }}</div>
      <div
        class="cuisine-location"
        @mouseover="checkOverflow2"
        @mouseleave="showTooltip2 = false"
      >
        位置：{{ cuisineinfo.location }}
      </div>
      <div v-if="showTooltip2" class="tooltip2">{{ cuisineinfo.location }}</div>
    </div>
    <button class="delete" @click="handleDelete">删除</button>

    <div v-if="showDeleteBox" class="overlay">
      <div class="deleteBox">
        <delete-box
          :deleteType="2"
          :itemId="cuisineinfo.id"
          @confirm="handleConfirmDelete"
          @cancel="handleCancelDelete"
        ></delete-box>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from 'axios';
import DeleteBox from "@/components/delete-box.vue"; // 确保路径正确

export default {
  name: "CuisineComponent",
  components: {
    DeleteBox,
  },
  props: {
    cuisineinfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDeleteBox: false,
      showCuisine: true,
      showTooltip1: false,
      showTooltip2: false,
      /*cuisineinfo:{
                name: '这是一个很长的文本这是一个很长的文本这是一个很长的文本',
                id: '1',
                likeCount: 12311,
                dislikeCount: 12211,
                location: '这是一个很长的文本',
                coverUrl:''
               } ,*/
    };
  },
  methods: {
    checkOverflow1(event) {
      const textElement = event.currentTarget;
      // 检查内容是否超出
      this.showTooltip1 = textElement.scrollWidth > textElement.clientWidth;
    },
    checkOverflow2(event) {
      const textElement = event.currentTarget;
      // 检查内容是否超出
      this.showTooltip2 = textElement.scrollWidth > textElement.clientWidth;
    },
    /*fetchCuisineInfo() {
            axios.post('/admin/cuisine/getByName')
                .then(response => {
                    this.cuisineData = {
                        id : response.data.id,
                        name : response.data.name,
                        coverUrl : response.data.coverUrl,
                        likeCount : Number(response.data.likeCount),
                        dislikeCount : Number(response.data.dislikeCount),
                        location : response.data.location,
                    };
                    
                })
                .catch(error => {
                    console.error('获取美食信息失败:', error);
                });
        },*/
    handleDelete() {
      this.showDeleteBox = true;
    },
    handleCancelDelete() {
      this.showDeleteBox = false;
    },
    handleConfirmDelete(code) {
      this.showDeleteBox = false;
      this.$emit("delete", code);
    },
  },
};
</script>

<style scoped>
.tooltip1 {
  position: absolute;
  background-color: rgba(237, 237, 237, 0.7);
  color: #575757;
  padding: 5px;
  border-radius: 10px;
  left: 240px;
  top: -50px; /* 位置可根据需要调整 */
  z-index: 10;
  width: 200px;
  height: auto;
  font-size: 15px;
}
.tooltip2 {
  position: absolute;
  background-color: rgba(237, 237, 237, 0.7);
  color: #575757;
  padding: 5px;
  border-radius: 10px;
  left: 240px;
  top: 50px; /* 位置可根据需要调整 */
  z-index: 10;
  width: 200px;
  height: auto;
  font-size: 15px;
}

.cuisine-info {
  position: relative;
  border-radius: 25px;
  border: 1px solid #eee;
  box-shadow: 0 4px 4px #00000015;
  background-color: #fff;
  width: 320px;
  height: 240px;
}

.id {
  font-size: 15px;
  margin-bottom: 7px;
  margin-top: 9px;
  text-align: left;
  margin-left: 20px;
} /*自增ID */

.divider {
  height: 1px;
  background-color: #e0e0e0;
  margin: 10px 0;
} /*分割线 */

.content {
  display: flex;
  align-items: center;
}

.image {
  border-radius: 10px;
  height: 100px;
  width: 140px;
  margin-left: 20px;
  margin-top: 5px;
}

.states {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.like-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  margin-left: 15px;
}
.dislike-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  margin-left: 30px;
}

.state-title {
  font-size: 13px;
  margin-bottom: 8px;
}

.icon {
  width: 35px;
  height: 35px;
  margin-bottom: 8px;
}

.dislike-icon {
  width: 30px;
  height: 30px;
  margin-bottom: 8px;
  margin-top: 4px;
}

.count {
  position: relative;
  font-size: 15px;
  margin-top: 8px;
  max-width: 41px; /* 设置最大宽度 */
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 使用省略号 */
}

.imformation {
  position: absolute;
  width: 240px;
  display: flex;
  flex-direction: column; /* 垂直方向排列 */
  align-items: flex-start; /* 子元素左对齐 */
  bottom: 10px; /* 可以根据需要调整 */
  margin-left: 20px;
  margin-top: 5px;
  font-size: 13px;
  width: 70%;
}
.cuisine-name {
  font-size: 15px;
  margin-bottom: 7px;
  bottom: 35px;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 使用省略号 */
  max-width: 230px; /* 设置最大宽度 */
  cursor: default;
}

.cuisine-location {
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 8px;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 使用省略号 */
  max-width: 230px; /* 设置最大宽度 */
  cursor: default;
}

.delete {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-size: 11px;
  background-color: #f65a5a;
  color: #fff;
  width: 50px;
}

.delete:hover {
  background-color: #e61616;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  z-index: 1000;
}

.deleteBox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
}
</style>
