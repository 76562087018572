<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="analysis">
    <SidebarComponent />
    <div class="content">
      <div class="head">
        <div class="chart-name">点赞条形图</div>
        <div class = "buttons">
          <div @click="selectCategory('food')" :class="{ 'button': true, 'active': currentCategory === 'food' }">美食</div>
          <div @click="selectCategory('recipe')" :class="{ 'button': true, 'active': currentCategory === 'recipe' }">菜谱</div>
        </div>
      </div>
      <div v-if="currentCategory === 'food'">
        <div class="chart" ref="echartCuisine" style="width: 100%;height: 500px; z-index: 20;"></div>
      </div>
      <div v-else-if="currentCategory === 'recipe'">
        <div class="chart" ref="echartRecipe" style="width: 100%;height: 500px; z-index: 20;"></div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import SidebarComponent from "@/components/sidebar.vue"; // 
  import { getTopTenCuisines } from '@/utils/api/cuisine.js'; // 假设你的接口函数在 @/api 文件夹下
  import { getTopTenRecipes } from '@/utils/api/recipe.js'; // 假设你的接口函数在 @/api 文件夹下
  import * as echarts from 'echarts';
// import { formatter } from "element-plus";


  export default {
    name: "AnalysisPage",
    components: {
      SidebarComponent
    },
    data() {
      return {
        currentCategory: 'food',    // 默认展示美食表格

        topCuisineLiked: [],        // 前十美食数据
        topRecipeLiked: [],         // 前十菜谱数据

      };
    },
    mounted() {
      this.updateCuisine();
      this.initCuisineChart();
      this.initRecipeChart();
      // 挂完 DOM 之后初始化
      window.addEventListener('resize', this.handleResize); // 添加事件监听器
    },
    methods: {
      

      // 转换【美食】和【菜谱】
      selectCategory(category) {
        this.currentCategory = category;
        if ( category === 'food' ){
          console.log("FOOD");
          this.updateCuisine();
          this.initCuisineChart(); // 数据更新后重新初始化图表
        } else {
          console.log("RECIPE");
          this.updateRecipe();
          this.initRecipeChart(); // 数据更新后重新初始化图表
        }
      },

      // 更新前十美食数据
      updateCuisine() {
        getTopTenCuisines().then(response => {
          // 假设接口返回的数据格式为 { data: [{}, {}, ...] }
          this.topCuisineLiked = response.data;
          console.log(this.topCuisineLiked);
          if (this.currentCategory === 'food') {
            this.initCuisineChart();        // 数据更新后重新初始化图表
          }
        }).catch(error => {
          console.error('获取前十美食数据失败:', error);
        });
      },

      // 更新前十菜谱数据
      updateRecipe() {
        getTopTenRecipes().then(response => {
          // 假设接口返回的数据格式为 { data: [{}, {}, ...] }
          this.topRecipeLiked = response.data;
          console.log(this.topRecipeLiked);
          if (this.currentCategory === 'recipe') {
            this.initRecipeChart();        // 数据更新后重新初始化图表
          }
        }).catch(error => {
          console.error('获取前十菜谱数据失败:', error);
        });
      },

      handleResize() {
        // 重新计算 tooltip 的位置
        const chartCuisine = this.$refs.echartCuisine;
        const chartRecipe = this.$refs.echartRecipe;
        if (chartCuisine) {
          const [tooltipX, tooltipY] = this.calculateTooltipPosition(chartCuisine.offsetWidth, chartCuisine.offsetHeight);
          const cChart = echarts.getInstanceByDom(chartCuisine);
          if (cChart) {
            cChart.setOption({
              tooltip: {
                position: [tooltipX, tooltipY]
              }
            });
          }
        }
        if (chartRecipe) {
          const [tooltipX, tooltipY] = this.calculateTooltipPosition(chartRecipe.offsetWidth, chartRecipe.offsetHeight);
          const rChart = echarts.getInstanceByDom(chartRecipe);
          if (rChart) {
            rChart.setOption({
              tooltip: {
                position: [tooltipX, tooltipY]
              }
            });
          }
        }
      },

      // 计算 tooltip 的位置
      calculateTooltipPosition(containerWidth, containerHeight) {
        // 这里你可以添加自己的逻辑来根据容器的尺寸计算 tooltip 的位置
        // 例如，我们这里将 tooltip 定位在容器的右下角
        const xPercent = containerWidth > 897 ? 60 : 10; 
        const yPercent = -6; 
        return [xPercent*0.01*containerWidth, yPercent*0.01*containerHeight];
      },

      // 初始化美食柱状图
      initCuisineChart() {
        console.log("初始化美食柱状图");
        // ref 名为 echartCuisine
        const chart = this.$refs.echartCuisine
        if ( chart ) {
          // 初始化 ECharts 实例
          const cChart = echarts.init(this.$refs.echartCuisine);

          // 提取点赞数和名字
          const likes = this.topCuisineLiked.map(item => parseInt(item.likeCount, 10));
          // const dislikes = this.topCuisineLiked.map(item => parseInt(item.dislikeCount, 10));
        
          // 获取容器的宽度和高度
          const containerWidth = chart.offsetWidth;
          const containerHeight = chart.offsetHeight;
          console.log(`containerWidth = ${containerWidth}   containerHeight = ${containerHeight}`)
          // 计算 tooltip 的位置
          const [tooltipX, tooltipY] = this.calculateTooltipPosition(containerWidth, containerHeight);
          console.log(`tooltipX = ${tooltipX}   tooltipY = ${tooltipY}`)
          // 配置 ECharts 选项
          const option = {
            // 悬浮效果
            tooltip: {
              backgroundColor: 'transparent', // 设置背景颜色为透明
              borderColor: 'transparent', // 设置边框颜色为透明
              borderWidth: 0, // 设置边框宽度为0
              shadowColor: 'transparent', // 设置阴影颜色为透明
              padding: 0, // 设置内边距为0
              shadowBlur: 0, // 设置阴影模糊度为0
              trigger: 'item',          // 鼠标悬浮在数据项上时触发
              axisPointer: {
                  type: 'none'
              },
              
              position: [tooltipX, tooltipY],     // 固定位置显示
              formatter: function(params) {
                const index = params.dataIndex;
                const item = this.topCuisineLiked[index];
                return `
                  <div style="width: 370px; height: 160px; background: white; border-radius: 25px; box-shadow: 0 2px 4px rgba(0,0,0,0.2);">
                    <img src="${item.coverUrl}" alt="${item.name}" style="width: 145px; height: 120px; margin-top: 23px; margin-left: 16px; float: left; margin-right: 10px;">
                    <div style="width: 160px; height: 100%; display: flex; flex-direction: column; justify-content: start;">
                      <div style="margin-left: 20px; margin-top: 20px; font-family: Microsoft YaHei; font-size: 16px; font-weight: 400; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; ">名称：${item.name}</div>
                      <div class="details" style="margin-top: 10px; display: flex; flex-direction: row; justify-content: center;">
                        <div class="detail" style="width: 50%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                          <div>点赞数</div>
                          <img src="https://eating-fun.oss-cn-beijing.aliyuncs.com/%E5%89%8D%E7%AB%AF/images%202.0/detail_like_on.png" style="width: 34px; height: 36px; margin-top: 8px; margin-bottom: 8px; display: flex;">
                          <div>${item.likeCount}</div>
                        </div>
                        <div class="detail" style="width: 50%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                          <div>点踩数</div>
                          <img src="https://eating-fun.oss-cn-beijing.aliyuncs.com/%E5%89%8D%E7%AB%AF/images%202.0/detail_dislike_on.png" style="width: 34px; height: 36px; margin-top: 8px; margin-bottom: 8px; display: flex;">
                          <div>${item.dislikeCount}</div>
                        </div>
                      </div>
                    </div>
                  </div>`;
              }.bind(this)         // 使用 .bind(this) 确保this指向 Vue 实例
            },
            xAxis: {
              type: 'category',
              name: '排名',                 // X轴名称
              nameLocation: 'end',       // X轴名称位置
              nameTextStyle: {              // X轴名称样式
                color: '#333',
                fontSize: 16
              },
              boundaryGap: ['0%', '20%'],     // 坐标轴两边留白策略，也可以使用布尔值，true居中
              axisLine: {
                show: true,      // 是否显示坐标轴轴线
                symbol: ['none', 'arrow'],   // 轴线两端箭头，两个值，none表示没有箭头，arrow表示有箭头
                symbolSize: [10, 15],        // 轴线两端箭头大小，数值一表示宽度，数值二表示高度
                lineStyle: {
                    color: '#333',    // 坐标轴线线的颜色
                    width: '1',       // 坐标轴线线宽
                    type: 'solid',    // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
                },
              },
              axisTick: {
                  show: false,       // 不显示坐标轴刻度
              },
              axisLabel: {
                  show: false,       // 不显示刻度标签
              },
            },
            yAxis: {
              type: 'value',
              name: '点赞数',                // Y轴名称
              nameLocation: 'end',          // Y轴名称位置
              nameTextStyle: {              // Y轴名称样式
                color: '#333',
                fontSize: 16
              },
              boundaryGap: ['0%', '10%'],     // 坐标轴两边留白策略，也可以使用布尔值，true居中
              splitLine: { show: false }, // 隐藏y轴的分割线
              axisLine: {
                show: true,      // 是否显示坐标轴轴线
                symbol: ['none', 'arrow'],   // 轴线两端箭头，两个值，none表示没有箭头，arrow表示有箭头
                symbolSize: [10, 15],        // 轴线两端箭头大小，数值一表示宽度，数值二表示高度
                lineStyle: {
                    color: '#333',    // 坐标轴线线的颜色
                    width: '1',       // 坐标轴线线宽
                    type: 'solid',    // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
                },
              },
              axisTick: {
                  show: false,       // 不显示坐标轴刻度
              },
              axisLabel: {
                  show: false,       // 不显示刻度标签
              },
            },
            series: [{
              data: likes,
              type: 'bar',
              label: {
                show: true, // 显示标签
                position: 'top', // 标签的位置
                formatter: likes // 标签的内容，{c} 表示当前数据点的值
              },
              itemStyle: {
                color: function(params) {
                  // params是一个对象，包含了当前柱状图的索引和数据信息
                  // 根据索引计算颜色
                  const index = params.dataIndex;
                  const gradientColors = ['#FF4A4A', '#FF6B5D', '#FF8F70', '#FFB193', '#FFD4B6', '#FFE7D9', '#FFF5EB', '#FFF7F8', '#FFFAF2', '#FFF5D9'];
                  return gradientColors[index % gradientColors.length]; // 循环使用颜色数组
                }
              }
            }]
          };

          // 使用刚指定的配置项和数据显示图表
          cChart.setOption(option);
          window.addEventListener('resize', function () {
            cChart.resize();
          })
        }
      },

      // 初始化菜谱柱状图
      initRecipeChart() {
        console.log("初始化菜谱柱状图");
        // ref 名为 echartRecipe
        const chart = this.$refs.echartRecipe;
        console.log(chart);
        if ( chart ) {
          // 初始化 ECharts 实例
          const rChart = echarts.init(this.$refs.echartRecipe);

          // 提取点赞数和名字
          // const names = this.topRecipeLiked.map(item => item.name);
          const likes = this.topRecipeLiked.map(item => parseInt(item.likeCount, 10));

          // 获取容器的宽度和高度
          const containerWidth = chart.offsetWidth;
          const containerHeight = chart.offsetHeight;
          console.log(`containerWidth = ${containerWidth}   containerHeight = ${containerHeight}`)
          // 计算 tooltip 的位置
          const [tooltipX, tooltipY] = this.calculateTooltipPosition(containerWidth, containerHeight);
          console.log(`tooltipX = ${tooltipX}   tooltipY = ${tooltipY}`)
        
          // 配置 ECharts 选项
          const option = {
            tooltip: {
              position: [tooltipX, tooltipY],     // 固定位置显示
              backgroundColor: 'transparent', // 设置背景颜色为透明
              borderColor: 'transparent', // 设置边框颜色为透明
              borderWidth: 0, // 设置边框宽度为0
              shadowColor: 'transparent', // 设置阴影颜色为透明
              padding: 0, // 设置内边距为0
              shadowBlur: 0, // 设置阴影模糊度为0
              formatter: function(params) {
                const index = params.dataIndex;
                const item = this.topRecipeLiked[index];
                return `
                  <div style=" width: 390px; height: 160px; background: white; border-radius: 25px; box-shadow: 0 2px 4px rgba(0,0,0,0.2);">
                    <img src="${item.coverUrl}" alt="${item.name}" style="width: 145px; height: 120px; margin-top: 23px; margin-left: 16px; float: left; margin-right: 10px;">
                    <div style="width: 200px; height: 100%; display: flex; flex-direction: column; justify-content: start;">
                      <div style="margin-left: 6px; margin-top: 20px; font-family: Microsoft YaHei; font-size: 16px; font-weight: 400; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; ">名称：${item.name}</div>
                      <div class="details" style="margin-top: 10px; display: flex; flex-direction: row; justify-content: center; font-family: Microsoft YaHei;font-size: 16px;">
                        <div class="detail" style="width: 33.3%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                          <div>点赞数</div>
                          <img src="https://eating-fun.oss-cn-beijing.aliyuncs.com/%E5%89%8D%E7%AB%AF/images%202.0/detail_like_on.png" style="width: 34px; height: 36px; margin-top: 8px; margin-bottom: 8px; display: flex;">
                          <div>${item.likeCount}</div>
                        </div>
                        <div class="detail" style="width: 33.3%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                          <div>点踩数</div>
                          <img src="https://eating-fun.oss-cn-beijing.aliyuncs.com/%E5%89%8D%E7%AB%AF/images%202.0/detail_dislike_on.png" style="width: 34px; height: 36px; margin-top: 8px; margin-bottom: 8px; display: flex;">
                          <div>${item.dislikeCount}</div>
                        </div>
                        <div class="detail" style="width: 33.3%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                          <div>收藏数</div>
                          <img src="https://eating-fun.oss-cn-beijing.aliyuncs.com/%E5%89%8D%E7%AB%AF/images%202.0/detail_collect_on.png" style="width: 34px; height: 36px; margin-top: 8px; margin-bottom: 8px; display: flex;">
                          <div>${item.collectCount}</div>
                        </div>
                      </div>
                    </div>
                  </div>`;
              }.bind(this)         // 使用 .bind(this) 确保this指向 Vue 实例
            },
            xAxis: {
              type: 'category',
              name: '排名',                 // X轴名称
              nameLocation: 'end',       // X轴名称位置
              nameTextStyle: {              // X轴名称样式
                color: '#333',
                fontSize: 16
              },
              boundaryGap: ['0%', '20%'],     // 坐标轴两边留白策略，也可以使用布尔值，true居中
              axisLine: {
                show: true,      // 是否显示坐标轴轴线
                symbol: ['none', 'arrow'],   // 轴线两端箭头，两个值，none表示没有箭头，arrow表示有箭头
                symbolSize: [10, 15],        // 轴线两端箭头大小，数值一表示宽度，数值二表示高度
                lineStyle: {
                    color: '#333',    // 坐标轴线线的颜色
                    width: '1',       // 坐标轴线线宽
                    type: 'solid',    // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
                },
              },
              axisTick: {
                  show: false,       // 不显示坐标轴刻度
              },
              axisLabel: {
                  show: false,       // 不显示刻度标签
              },
            },
            yAxis: {
              type: 'value',
              name: '点赞数',                // Y轴名称
              nameLocation: 'end',          // Y轴名称位置
              nameTextStyle: {              // Y轴名称样式
                color: '#333',
                fontSize: 16
              },
              boundaryGap: ['0%', '10%'],     // 坐标轴两边留白策略，也可以使用布尔值，true居中
              splitLine: { show: false }, // 隐藏y轴的分割线
              axisLine: {
                show: true,      // 是否显示坐标轴轴线
                symbol: ['none', 'arrow'],   // 轴线两端箭头，两个值，none表示没有箭头，arrow表示有箭头
                symbolSize: [10, 15],        // 轴线两端箭头大小，数值一表示宽度，数值二表示高度
                lineStyle: {
                    color: '#333',    // 坐标轴线线的颜色
                    width: '1',       // 坐标轴线线宽
                    type: 'solid',    // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
                },
              },
              axisTick: {
                  show: false,       // 不显示坐标轴刻度
              },
              axisLabel: {
                  show: false,       // 不显示刻度标签
              },
            },
            series: [{
              data: likes,
              type: 'bar',
              label: {
                show: true, // 显示标签
                position: 'top', // 标签的位置
                formatter: likes // 标签的内容，{c} 表示当前数据点的值
              },
              itemStyle: {
                color: function(params) {
                  // params是一个对象，包含了当前柱状图的索引和数据信息
                  // 根据索引计算颜色
                  const index = params.dataIndex;
                  const gradientColors = ['#FF4A4A', '#FF6B5D', '#FF8F70', '#FFB193', '#FFD4B6', '#FFE7D9', '#FFF5EB', '#FFF7F8', '#FFFAF2', '#FFF5D9'];
                  return gradientColors[index % gradientColors.length]; // 循环使用颜色数组
                }
              }
            }],
            
          };
          console.log("打印美食数据");
          console.log(option);
          // 使用刚指定的配置项和数据显示图表
          rChart.setOption(option);
          window.addEventListener('resize', function () {
            // const newHeight = chart.offsetHeight;
            rChart.resize();
          })
        }
      },

      // 监听变化
      watch: {
        topCuisineLiked(newVal) {
          console.log(newVal);
          if (this.currentCategory === 'food') {
            this.initCuisineChart();
          }
        },
        topRecipeLiked(newVal) {
          console.log(newVal);
          if (this.currentCategory === 'recipe') {
            this.initRecipeChart();
          }
        }
      }
    },
  };
</script>
  
<style scoped>
.analysis {
  display: flex;
}

.content {
  width: 110vh;
  height: 655px;
  flex: 1;
  padding: 20px;
  margin-top: 18vh;
  margin-left: 28vh;
  margin-right: 5vh;
  border-radius: 25px;
  background-color: rgb(255, 255, 255);
  box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.15);
  
  
}

.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid rgba(207, 207, 207, 1);     /*  */
}

.chart-name {
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  /* background-color: aqua; */
  /* 文字格式 */
  font-family: Microsoft YaHei;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.buttons {
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* background-color: bisque; */
}

.button {
  width: 84px;
  height: 38px;
  top: 15px;
  left: 834px;
  gap: 0px;
  border-radius: 25px;
  border: 1px solid rgba(151, 151, 151, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  /* 文字格式 */
  font-family: Microsoft YaHei;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.4px;
  text-align: center;
  /* vertical-align: middle; */
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.button.active {
  background-color: rgba(254, 214, 34, 1);
  
}

/* 表格 */
.chart {
  margin-top: 5%;
  height: 30vh;
}

</style>