<template>
  <div class="dashboard">
    <aside class="sidebar">
      <div class="logo">
        <img src="@/assets/login_avatar.png" alt="avatar" />
        <span>吃饱饱</span>
      </div>
      <ul class="menu">
        <li
          v-for="item in menuItems"
          :key="item.name"
          :class="{ active: selectedItem === item.name }"
          @click="selectItem(item)"
        >
          <i :class="item.icon"></i>
          <span>{{ item.label }}</span>
        </li>
      </ul>
    </aside>

    <header class="header">
      <div class="header-item">
        <i :class="selectedItemIcon"></i>
        <span>{{ selectedItemLabel }}</span>
      </div>
      <div class="header-user">
        <span>你好！ 管理员</span>
        <img src="@/assets/user_icon.png" alt="user-icon" />
      </div>
    </header>

    <main class="content">
      <!-- 这里是内容区，可以根据selectedItem动态渲染内容 -->
    </main>
  </div>
</template>

<script>
export default {
  name: "SidebarComponent",
  data() {
    return {
      selectedItem: "home", // 默认选择首页
      menuItems: [
        { name: "home", label: "首页", icon: "icon-home" },
        { name: "recipe", label: "菜谱管理", icon: "icon-book" },
        { name: "cuisine", label: "美食管理", icon: "icon-food" },
        { name: "user", label: "用户管理", icon: "icon-user" },
        { name: "map", label: "美食地图", icon: "icon-map" },
        { name: "analysis", label: "数据分析", icon: "icon-chart" },
      ],
    };
  },
  computed: {
    selectedItemLabel() {
      const item = this.menuItems.find((i) => i.name === this.selectedItem);
      return item ? item.label : "";
    },
    selectedItemIcon() {
      const item = this.menuItems.find((i) => i.name === this.selectedItem);
      return item ? item.icon : "";
    },
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item.name; // 立即更新selectedItem
      this.$router.push({ path: `/${item.name}` }); // 路由导航
    },
  },
  watch: {
    // 监听路由变化
    $route(to) {
      const item = this.menuItems.find((i) => i.name === to.path.slice(1));
      if (item) {
        this.selectedItem = item.name; // 更新选中项
      }
    },
  },
  mounted() {
    // 初始化时设置当前选中项
    const routeName = this.$route.path.slice(1);
    const item = this.menuItems.find((i) => i.name === routeName);
    if (item) {
      this.selectedItem = item.name; // 如果有对应项，设置为当前选中项
    }
  },
};
</script>

<style scoped>
.dashboard {
  display: flex;
  height: 100vh;
  background-color: #f9f9f9; /* 主背景颜色 */
  z-index: 999;
}

.sidebar {
  width: 200px;
  height: 100%;
  background-color: #ffffff; /* 环境颜色 */
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* 添加阴影 */
  position: fixed; /* 固定在侧边 */
}

.logo {
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: #ffe9b0; /* Logo背景颜色 */
  padding: 15px 20px; /* 调整内边距 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #d3d2cd;
  margin-top: 10px;
}

.logo span {
  font-size: 18px;
  color: #976022;
}

.logo img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu li {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: #333;
  cursor: pointer;
  transition: all 0.3s; /* 添加过渡效果 */
  border-bottom: 1px solid #d3d2cd;
}

.menu li:hover {
  background-color: #fff9e9; /* 悬停背景颜色 */
}

.menu li i {
  margin-right: 10px;
  font-size: 18px;
}

.menu li.active {
  background-color: #fff9e9; /* 高亮背景颜色 */
  color: #d8a31b;
}

.header {
  position: fixed; /* 固定在顶部 */
  top: 0; /* 在顶部 */
  left: 210px; /* 与sidebar对齐 */
  width: calc(100% - 240px); /* 页面宽度减去sidebar宽度 */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* 确保在其他元素上方 */
}

.header-item {
  display: flex;
  align-items: center;
}

.header-item i {
  margin-right: 5px;
  font-size: 18px;
}

.header-user {
  display: flex;
  align-items: center;
}

.header-user span {
  margin-right: 10px;
}

.header-user img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.icon-home,
.icon-book,
.icon-food,
.icon-user,
.icon-map,
.icon-chart {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-home {
  background-image: url("@/assets/icon_home.png");
}
.icon-book {
  background-image: url("@/assets/icon_book.png");
}
.icon-food {
  background-image: url("@/assets/icon_food.png");
}
.icon-user {
  background-image: url("@/assets/icon_user.png");
}
.icon-map {
  background-image: url("@/assets/icon_map.png");
}
.icon-chart {
  background-image: url("@/assets/icon_chart.png");
}
</style>
