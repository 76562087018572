import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
// 关闭 Vue 的生产提示
// 在 Vue 3 中，使用 createApp() 时不需要设置 productionTip
// Vue.config.productionTip = false;

// 创建 Vue 应用实例
const app = createApp(App);

// 使用路由
app.use(router);
// 全局注册 Element Plus 组件
app.use(ElementPlus);

// 挂载 Vue 应用实例
app.mount('#app');