import axios from "axios";

// 管理员登录
export function adminLogin(data) {
  return axios({
    url: "http://118.31.244.23:8989/admin/admin/login",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      username: data.username,
      password: data.password,
    },
  });
}
