<template>
  <div class="map" @click="handleOutsideClick">
    <!-- 侧边栏组件 -->
    <SidebarComponent />

    <!-- 主内容区域 -->
    <div class="main-content">
      <!-- 城市选择下拉菜单 -->
      <div class="city-selector">
        <label for="city" class="city-label">请选择地区</label>
        <el-select v-model="selectedCityName" placeholder="选择城市" @change="openDialogBySelect" class="city-select">
          <el-option v-for="(city, index) in cities" :key="index" :label="city.name" :value="city.name" />
        </el-select>
      </div>

      <!-- 地图容器 -->
      <el-scrollbar class="map-container">
        <!-- 城市区域，点击事件展示美食信息 -->
        <div
          v-for="(city, index) in cities"
          :key="index"
          :style="getCityAreaStyle(city.position)"
          @click.stop="handleCityClick(city)"
          class="city-area"
        ></div>

        <!-- 点击后显示的美食信息，在城市区域位置展示 -->
        <CuisineComponent 
          v-if="hoveredCuisine" 
          :cuisine="hoveredCuisine" 
          class="cuisine-info-fixed" 
          :style="cuisinePosition"
          ref="cuisineInfo"
        />

        <!-- Loading message while the data is being fetched -->
        <div v-if="loading" class="loading-overlay">
          <div class="spinner">加载中...</div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import SidebarComponent from "@/components/sidebar.vue";
import CuisineComponent from "@/components/mapinfo.vue";
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { getCuisineByProvince } from "@/utils/api/cuisine"; // Correct path for the API function

export default {
  name: "MapPage",
  components: {
    SidebarComponent,
    CuisineComponent
  },
  setup() {
    const cities = ref([
    {
        name: '三明市',
        position: { top: '39%', left: '38%', width: '7%', height: '7%' },
        description: 'Description of 三明市',
        cuisine: { name: '三明小吃', location: '三明市', coverUrl: '', likeCount: 100, dislikeCount: 10 }
      },
      {
        name: '泉州市',
        position: { top: '58%', left: '49%', width: '7%', height: '7%' },
        description: 'Description of 泉州市',
        cuisine: { name: '泉州小吃', location: '泉州市', coverUrl: '', likeCount: 100, dislikeCount: 10 }
      },
      {
        name: '厦门市',
        position: { top: '70%', left: '48%', width: '7%', height: '7%' },
        description: 'Description of 厦门市',
        cuisine: { name: '厦门小吃', location: '厦门市', coverUrl: '', likeCount: 100, dislikeCount: 10 }
      },
      {
        name: '漳州市',
        position: { top: '76%', left: '40%', width: '7%', height: '7%' },
        description: 'Description of 漳州市',
        cuisine: { name: '漳州小吃', location: '漳州市', coverUrl: '', likeCount: 100, dislikeCount: 10 }
      },
      {
        name: '莆田市',
        position: { top: '53%', left: '56%', width: '7%', height: '7%' },
        description: 'Description of 莆田市',
        cuisine: { name: '莆田小吃', location: '莆田市', coverUrl: '', likeCount: 100, dislikeCount: 10 }
      },
      {
        name: '福州市',
        position: { top: '41%', left: '58%', width: '7%', height: '7%' },
        description: 'Description of 福州市',
        cuisine: { name: '福州小吃', location: '福州市', coverUrl: '', likeCount: 100, dislikeCount: 10 }
      },
      {
        name: '宁德市',
        position: { top: '23%', left: '62%', width: '7%', height: '7%' },
        description: 'Description of 宁德市',
        cuisine: { name: '宁德小吃', location: '宁德市', coverUrl: '', likeCount: 100, dislikeCount: 10 }
      },
      {
        name: '南平市',
        position: { top: '18%', left: '46%', width: '7%', height: '7%' },
        description: 'Description of 南平市',
        cuisine: { name: '南平小吃', location: '南平市', coverUrl: '', likeCount: 100, dislikeCount: 10 }
      },
      {
        name: '龙岩市',
        position: { top: '59%', left: '32%', width: '7%', height: '7%' },
        description: 'Description of 龙岩市',
        cuisine: { name: '龙岩小吃', location: '龙岩市', coverUrl: '', likeCount: 100, dislikeCount: 10 }
      },
      // ... other city data
    ]);
    const selectedCityName = ref(null);
    const hoveredCuisine = ref(null);
    const cuisinePosition = ref({});
    const loading = ref(false); // Flag to track loading state

    // Handle city click (display loading until data is updated)
    const handleCityClick = (city) => {
      if (loading.value) {
        // Set loading state and display loading message in the details view
        hoveredCuisine.value = { name: "加载中...", location: "" }; // Temporary loading text
        cuisinePosition.value = city.position;
      } else {
        // Show actual cuisine data after loading
        showCuisine(city);
      }
    };

    const openDialogBySelect = () => {
      const city = cities.value.find(c => c.name === selectedCityName.value);
      if (city) showCuisine(city);
    };

    const showCuisine = (city) => {
      hoveredCuisine.value = city.cuisine;
      cuisinePosition.value = {
        top: city.position.top,
        left: city.position.left,
        transform: 'translate(-50%, -50%)',
        position: 'absolute'
      };
    };

    const getCityAreaStyle = (position) => ({
      position: 'absolute',
      top: position.top,
      left: position.left,
      width: position.width,
      height: position.height,
      backgroundColor: 'rgba(255, 0, 0, 0)', /* Adjust visibility or color */
      cursor: 'pointer'
    });

    const handleOutsideClick = (event) => {
      const cuisineInfo = document.querySelector('.cuisine-info-fixed');
      if (hoveredCuisine.value && (!cuisineInfo || !cuisineInfo.contains(event.target))) {
        hoveredCuisine.value = null;
      }
    };

    const fetchCityCuisineData = async (province) => {
  loading.value = true; // Set loading state to true before fetching data
  console.log("正在为省份获取美食数据：", province);
  try {
    const response = await getCuisineByProvince(province);
    const fetchedCities = response.data || [];
    
    // Log the fetched city data from the API
    console.log("从接口获取的城市数据：", fetchedCities);

    // Update the cities array with fetched data and log the changes
    cities.value = cities.value.map(city => {
      const fetchedCity = fetchedCities.find(fetched => fetched.cityName === city.name);

      if (fetchedCity) {
        // Log the data for this city
        console.log(`更新城市: ${city.name}`, fetchedCity);

        return {
          ...city,
          cuisine: {
            name: fetchedCity.name || city.cuisine.name,
            location: fetchedCity.location || city.cuisine.location,
            coverUrl: fetchedCity.coverUrl || city.cuisine.coverUrl,
            likeCount: fetchedCity.likeCount || city.cuisine.likeCount,
            dislikeCount: fetchedCity.dislikeCount || city.cuisine.dislikeCount,
          }
        };
      }

      // Log if no update is made to the city
      console.log(`未更新城市: ${city.name}`);
      return city;
    });

    // Log the updated cities array
    console.log("更新后的城市数据：", cities.value);
  } catch (error) {
    console.error("获取美食数据失败：", error);
  } finally {
    loading.value = false; // Set loading state to false after fetching is complete
  }
};

    onMounted(() => {
      document.addEventListener('click', handleOutsideClick);
      fetchCityCuisineData('福建省');  // Replace with the correct province
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleOutsideClick);
    });

    return {
      cities,
      selectedCityName,
      hoveredCuisine,
      cuisinePosition,
      loading,  // Expose the loading state
      openDialogBySelect,
      handleCityClick,
      showCuisine,
      getCityAreaStyle
    };
  }
};
</script>

<style scoped>
/* Map Styles */
.map {
  display: flex;
  height: 100vh;
}

.main-content {
  flex: 1;
  margin-top: 150px;
  margin-left: 250px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.city-selector {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.city-label {
  margin-right: 10px;
  white-space: nowrap;
  width: 80px;
}

.city-select {
  width: 150px;
}

.map-container {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  background-image: url('@/assets/map.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.city-area {
  transition: background-color 0.3s ease;
}

.cuisine-info-fixed {
  z-index: 100;
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}
</style>
