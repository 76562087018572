// 此文件专门负责项目的路由

import { createRouter, createWebHistory } from "vue-router";
// 引入组件
import Login from "@/views/login.vue";
import Home from "@/views/home.vue";
import Recipe from "@/views/recipe.vue";
import Cuisine from "@/views/cuisine.vue";
import User from "@/views/user.vue";
import Map from "@/views/map.vue";
import Analysis from "@/views/analysis.vue";
// 创建路由数组
const routes = [
  {
    path: "/",
    redirect: "/login", // 重定向
  },
  {
    path: "/login", // 路径
    component: Login, // 跳转到的组件
  },
  {
    path: "/home", // 路径
    component: Home, // 跳转到的组件
  },
  {
    path: "/recipe", // 路径
    component: Recipe, // 跳转到的组件
  },
  {
    path: "/cuisine", // 路径
    component: Cuisine, // 跳转到的组件
  },
  {
    path: "/user", // 路径
    component: User, // 跳转到的组件
  },
  {
    path: "/map", // 路径
    component: Map, // 跳转到的组件
  },
  {
    path: "/analysis", // 路径
    component: Analysis, // 跳转到的组件
  },
];

// 创建路由器实例
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

// 导出路由器实例
export default router;
