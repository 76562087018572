<template>
  <div class="login-container">
    <div class="login-box">
      <img src="@/assets/login_avatar.png" alt="avatar" class="avatar" />
      <form @submit.prevent="onLogin">
        <div class="input-group">
          <label for="username">账号</label>
          <input
            type="text"
            id="username"
            v-model="username"
            placeholder="请输入账号"
          />
        </div>
        <div class="input-group">
          <label for="password">密码</label>
          <input
            type="password"
            id="password"
            v-model="password"
            placeholder="请输入密码"
          />
        </div>
        <p v-if="errorMsg" class="error-message">{{ errorMsg }}</p>
        <button type="submit" class="login-button">登录</button>
      </form>
    </div>
  </div>
</template>

<script>
import { adminLogin } from "@/utils/api/admin.js";
export default {
  name: "LoginPage",
  data() {
    return {
      username: "",
      password: "",
      errorMsg: "", // 用于存储错误信息
    };
  },
  methods: {
    async onLogin() {
      if (!this.username || !this.password) {
        this.errorMsg = "账号或密码不能为空！";
        return;
      }

      try {
        this.errorMsg = ""; // 清空错误信息
        const response = await adminLogin({
          username: this.username,
          password: this.password,
        });

        const msg = response.data.msg;
        if (response.data.code === 200) {
          const token = response.data.data;
          localStorage.setItem("token", token);
          this.$router.push("/home");
        } else {
          this.errorMsg = msg || "账号或密码错误！";
        }
      } catch (error) {
        this.errorMsg = "登录失败，请稍后再试！";
        console.error("登录失败:", error);
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.login-box {
  width: 300px;
  padding: 40px;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-align: center;
  min-height: 400px;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 20px;
  background-color: #fdd835;
  display: inline-block;
}

.input-group {
  margin-bottom: 15px;
  text-align: left;
}

.input-group label {
  font-size: 14px;
  color: #333;
}

.input-group input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 25px;
  font-size: 14px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 15px;
  text-align: left;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #fdd835;
  border: none;
  border-radius: 25px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #fbc02d;
}
</style>
