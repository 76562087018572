<template>
  <div class="card" v-if="recipe">
    <div class="card-header">菜谱</div>
    <div class="divider"></div>
    <div class="card-content">
      <img :src="recipe.coverUrl" class="image" />
      <div class="stats">
        <div class="stat-item">
          <div class="stat-title">点赞数</div>
          <img src="@/assets/like.png" alt="like" class="icon" />
          <div class="count">{{ recipe.likeCount }}</div>
        </div>
        <div class="stat-item">
          <div class="stat-title">点踩数</div>
          <div class="dislike">
            <img src="@/assets/dislike3.png" alt="dislike" class="dislike-icon" />
          </div>
          <div class="count">{{ recipe.dislikeCount }}</div>
        </div>
        <div class="stat-item">
          <div class="stat-title">收藏数</div>
          <img src="@/assets/collect.png" alt="collect" class="icon" />
          <div class="count">{{ recipe.collectCount }}</div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="recipe-name">名称：{{ recipe.name }}</div>
      <div class="buttons">
        <button class="button yellow" @click="showRecipeDetail">详情信息</button>
        <button 
          class="button yellow" 
          :disabled="!recipe.isSystemRecipe" 
          :class="{ 'disabled': !recipe.isSystemRecipe }"
           @click="editRecipe(recipe.id)"
        >
          修改
        </button>
        <button class="button red" @click="handleDelete">删除</button>
      </div>
    </div>
    
    <!-- 删除确认弹窗 -->
    <div v-if="showDeleteConfirmation" class="overlay">
      <div class="delete-confirmation">
        <DeleteConfirmation
          @cancel="handleCancelDelete"
          @confirm="handleConfirmDelete"
          :delete-type="3" 
          :item-id="recipe.id"  
        />
      </div>
    </div>

    <!-- 详情信息弹窗 -->
    <div v-if="showRecipeDetailPopup" class="overlay">
      <div class="recipe-detail-popup">
        <button class="close-button" @click="showRecipeDetailPopup = false">
            <img src="@/assets/close1.png" alt="关闭" class="close-icon"/>
        </button>
        <RecipeDetail :recipeId="recipe.id" @close="showRecipeDetailPopup = false" />
      </div>
    </div>

        <!-- 编辑菜谱弹窗 -->
    <div v-if="showEditPopup" class="overlay">
      <div class="edit-recipe-popup">
        <button class="close-button2" @click="showEditPopup = false">
          <img src="@/assets/close1.png" alt="关闭" class="close-icon"/>
        </button>
        <EditRecipe :recipeId="recipe.id" 
        @close="showEditPopup = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DeleteConfirmation from "@/components/delete-box.vue";
import RecipeDetail from "@/components/showRecipe.vue"; // 引入详细信息组件
import EditRecipe from "@/components/EditRecipe.vue"; 

export default {
  name: "CardComponent",
  components: {
    DeleteConfirmation,
    RecipeDetail,
    EditRecipe,
  },
  props: {
    recipe: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showDeleteConfirmation: false,
      showRecipeDetailPopup: false, // 控制详细信息弹窗的显示
      showEditPopup: false,
    };
  },
  methods: {
    handleDelete() {
      this.showDeleteConfirmation = true; 
    },
    handleConfirmDelete() {
      alert('菜谱删除成功');
      this.$emit('delete', Number(this.recipe.id)); 
      this.showDeleteConfirmation = false; 
    },
    handleCancelDelete() {
      this.showDeleteConfirmation = false; 
    },
    showRecipeDetail() {
      this.showRecipeDetailPopup = true; // 显示详细信息弹窗
    },
    editRecipe(recipeId) {
      this.showEditPopup = true; // 显示编辑菜谱弹窗
      console.log("Editing recipe with ID:", recipeId);
    }
  }
};
</script>

<style scoped>
.card {
  border-radius: 20px;
  border: 1px solid #eee;
  padding: 10px;
  width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
    z-index: 1000; 
}

.close-icon {
  width: 30px; 
  height: 30px; 
}
.close-button {
  position: absolute; /* 绝对定位 */
  top: 60px; /* 距离顶部10px */
  right: -40px; /* 距离右边10px */
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.recipe-detail-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    z-index: 1001; 
}

.edit-recipe-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    margin-top: 20px;
  }

.close-button2 {
  position: absolute; /* 绝对定位 */
  top: -35px; /* 距离顶部10px */
  right: -40px; /* 距离右边10px */
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.delete-confirmation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    z-index: 1001; 
}

.card-header {
  font-size: 15px;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 10px;
}

.divider {
  height: 1px; /* 分隔线高度 */
  background-color: #E0E0E0; /* 分隔线颜色 */
  margin: 10px 0; /* 上下外边距 */
}

.card-content {
  display: flex;
  align-items: center;
}

.image {
  border-radius: 10px;
  width: 140px;
  height: 100px;
}

.stats {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  margin-left: 10px;
}

.stat-title {
  font-size: 12px;
  margin-bottom: 8px;
}

.icon {
  width: 28px;
  height: 28px;
  margin-bottom: 8px;
}

.dislike {
  display: flex;
  width: 28px;
  height: 28px;
  margin-bottom: 8px;   
}

.dislike-icon {
  width: 24px;
  height: 24px; 
  margin-top: 2px;
}

.count {
  font-size: 12px;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 15px
}

.recipe-name {
  font-size: 13px;
  white-space: nowrap;  /* 不换行 */
  overflow: hidden;     /* 隐藏溢出部分 */
  text-overflow: ellipsis; /* 使用省略号表示隐藏的部分 */
  max-width: 130px;     
}

.buttons {
  display: flex;
  gap: 5px;
}

.button {
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-size: 11px;
}

.yellow {
  background-color: #ffd400;
}

.red {
  background-color: #f65a5a;
  color: white;
}

.disabled {
  background-color: #d3d3d3; /* 灰色 */
  cursor: not-allowed; /* 禁用样式 */
}
</style>
