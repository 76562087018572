<template>
  <div class="scroll-container">
    <div class="left-section">
      <!-- 图片 -->
      <div class="add-images">
        <div class="cover-page" @click="uploadCover">
          <img :class="{'have-images': coverUrl.length > 0, 'un-images': coverUrl.length === 0}" :src="coverImageUrl" />
          <div v-if="coverUrl.length === 0">添加封面</div>
        </div>
      </div>
      <!-- 输入 -->
      <div class="input-section">  
        <div class="input-cell-title">菜名:</div>
        <div class="input-cell-after-title">
          <input class="name-input" maxlength="40" @input="bindNameInput" v-model="cName"/>
        </div>
      </div>
      <div class="input-section">  
        <div class="input-cell-title">菜品描述:</div>
        <div class="input-cell-after-title">
          <textarea class="describe-input" maxlength="512" @input="bindDescInput" v-model="description"/>
        </div>
      </div>
      <!-- 选择 口味 工艺 -->
      <div class="choose-section">
        <div class="input-cell-title">口味</div>
        <div class="button-group">
          <div v-for="(item, index) in buttonsKW" :key="index" 
              class="button" :class="{selected: selectedKW === item}" @click="onKWTap(item)">
              {{ item }}
          </div>
        </div>
      </div>
      <div class="choose-section">
        <div class="input-cell-title">工艺</div>
        <div class="button-group">
          <div v-for="(item, index) in buttonsGY" :key="index" 
              class="button" :class="{selected: selectedGY === item}" @click="onGYTap(item)">
              {{ item }}
          </div>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="right-section">
      <div class="scrollable-content">
        <!-- 输入食材（主料和辅料） -->
        <div class="ingredients-section">  
          <div class="ingredients-title">主料
          <img class="ingredients-add" src="@/assets/add.png" @click="addMainIngredient"/>
         </div> 
          <div class="ingredients-detail">
            <div v-for="(item, index) in mainIngres" :key="index" class="ingredients-list">
              <div class="ingredients-type">
                <input class="input-type" placeholder="输入主料名称" v-model="item.type" @input="bindInputMainIngre" :data-field="'type'" :data-index="index"/>
              </div>
              <div class="ingredients-usage">
                <input class="input-usage" placeholder="用量" v-model="item.usage" @input="bindInputMainIngre" :data-field="'usage'" :data-index="index"/>
              </div>
              <img v-if="mainIngres.length > 1" src="@/assets/delete.png" class="delete-icon" @click="removeMainIngredient(index)"/>
            </div>
          </div>
        </div>

        <div class="ingredients-section">  
          <div class="ingredients-title">辅料
          <img class="ingredients-add" src="@/assets/add.png" @click="addAssistIngredient"/>
          </div>
          <div class="ingredients-detail">
            <div v-for="(item, index) in assistIngres" :key="index" class="ingredients-list">
              <div class="ingredients-type">
                <input class="input-type" placeholder="输入辅料名称" v-model="item.type" @input="bindInputAssistIngre" :data-field="'type'" :data-index="index"/>
              </div>
              <div class="ingredients-usage">
                <input class="input-usage" placeholder="用量" v-model="item.usage" @input="bindInputAssistIngre" :data-field="'usage'" :data-index="index"/>
              </div>
              <img v-if="assistIngres.length > 1" src="@/assets/delete.png" class="delete-icon" @click="removeAssistIngredient(index)"/>
            </div>
          </div>
        </div>

        <!-- 输入步骤 -->
        <div class="steps-section">
          <div class="steps-title">
            <div class="steps-title-text">制作步骤</div>
            <img class="steps-add" src="@/assets/add.png" @click="addSteps"/>
          </div>
          <!-- 步骤列表 -->
          <div v-for="(item, index) in steps" :key="index" class="step">
            <div class="step">
              <div class="step-header">  <!-- 添加一个新的div作为步骤标题的容器 -->
                <div class="step-num">步骤{{ index + 1 }}</div>
                <img v-if="steps.length > 1" src="@/assets/delete.png" class="delete-icon" @click="removeStep(index)"/>
              </div>
              <div class="step-body">
                <div class="step-upload-image">
                  <img :src="item.stepImg || recipeAddImage" class="step-image" @click="uploadStepImage" :data-index="index"/>
                </div>
                <textarea class="step-input-detail"  placeholder="步骤描述" v-model="item.stepDesc" @input="bindStepDescInput" :data-index="index"></textarea>
              </div>
            </div>
          </div>
        </div>

        <!-- 发布 -->
        <div class="release" @click="uploadImages">
          <div class="release-text">发   布</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
// import { useRouter } from 'vue-router';
import { defineProps} from 'vue';
import coverAddImage from '@/assets/cover_add.png'; 
import recipeAddImage from '@/assets/recipe_add_image.png'; 
import { uploadRecipeImages,getRecipeDetail } from '@/utils/api/recipe'; // 假设你已经定义了这个接口
import { updateRecipe } from '@/utils/api/recipe'; // 假设你已经定义了这个接口

// 初始化
// const router = useRouter();
// 接收 props
const props = defineProps({
  recipeId: {
    type: Number,
    required: true
  }
});
// 响应式数据
const coverUrl = ref([]);       // 封面图片
const cName = ref('');          // 菜品名称
const description = ref('');    // 菜品描述
const buttonsKW = ref(['五香', '葱香', '孜然', '酸辣', '甜辣', '清淡', '酱香', '咖喱', '更多']);
const buttonsGY = ref(['炒', '焖', '炖', '蒸', '拌', '烤', '炸', '卤', '更多']);
const selectedKW = ref('');
const selectedGY = ref('');
const mainIngres = ref([{ type: '', usage: '' }]);
const assistIngres = ref([{ type: '', usage: '' }]);
const steps = ref([{ stepNum: '1', stepImg: '', stepDesc: '' }]);
const initCoverUrl = ref('');

const coverImageUrl = computed(() => {
  return coverUrl.value.length > 0 ? coverUrl.value[0].tempFilePath : coverAddImage;
});

// 在组件加载时获取菜谱详情
onMounted(async () => {
  
  const response = await getRecipeDetail(props.recipeId);
  
  if (response.code === 200) {
    const recipe = response.data; // 获取菜谱详情
    coverUrl.value = [{ tempFilePath: recipe.coverUrl }];
    initCoverUrl.value = recipe.coverUrl;
    cName.value = recipe.name;
    description.value = recipe.description;
    
    // 映射口味和工艺
    const tasteMapping = {
      '1': '五香', '2': '葱香', '3': '孜然', '4': '酸辣', 
      '5': '甜辣', '6': '清淡', '7': '酱香', '8': '咖喱', 
      '9': '更多'
    };
    const techniqueMapping = {
      '1': '炒', '2': '焖', '3': '炖', '4': '蒸', 
      '5': '拌', '6': '烤', '7': '炸', '8': '卤', 
      '9': '更多'
    };
    
    selectedKW.value = tasteMapping[recipe.taste];
    selectedGY.value = techniqueMapping[recipe.technique];

    // 处理食材
    const ingredientsParts = recipe.ingredients.split('|');
    mainIngres.value = ingredientsParts[0].split(',').map(ing => {
      const [type, usage] = ing.split('~');
      return { type, usage };
    });

    if (ingredientsParts.length > 1) {
      assistIngres.value = ingredientsParts[1].split(',').map(ing => {
        const [type, usage] = ing.split('~');
        return { type, usage };
      });
    }

    // 处理步骤
    steps.value = recipe.steps.map((step, index) => ({
      stepNum: index + 1,
      stepImg: step.imageUrl,
      stepDesc: step.description
    }));

    console.log(recipe);
  } else {
    alert('获取菜谱详情失败，请重试。');
  }
});
// 上传封面图片
function uploadCover() {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*';
  input.onchange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        coverUrl.value = [file];
        coverUrl.value[0].tempFilePath = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  input.click();
}

function uploadStepImage(event) {
  const index = parseInt(event.target.dataset.index);
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*';
  input.onchange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        steps.value[index].stepImg = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  input.click();
}

// 绑定菜品名称输入
function bindNameInput(event) {
  cName.value = event.target.value;
}

// 绑定菜品描述输入
function bindDescInput(event) {
  description.value = event.target.value;
}

// 选择口味
function onKWTap(item) {
  selectedKW.value = selectedKW.value === item ? '' : item; // 点击同一按钮则取消选择
}

// 选择工艺
function onGYTap(item) {
  selectedGY.value = selectedGY.value === item ? '' : item; // 点击同一按钮则取消选择
}

// 添加主料
function addMainIngredient() {
  // 检查主料是否有未填写的输入框
  const hasEmptyInput = mainIngres.value.some(ingredient => ingredient.type === '' || ingredient.usage === '');
  if (hasEmptyInput) {
    alert('请先填写空的主料输入框。'); 
    return; // 阻止添加新的输入框
  }
  mainIngres.value.push({ type: '', usage: '' });
}

// 绑定主料输入
function bindInputMainIngre(event) {
  const index = parseInt(event.target.dataset.index);
  const field = event.target.dataset.field;
  mainIngres.value[index][field] = event.target.value;
}

// 删除主料
function removeMainIngredient(index) {
  if (mainIngres.value.length > 1) {
    mainIngres.value.splice(index, 1);
  }
}

// 删除辅料
function removeAssistIngredient(index) {
  if (assistIngres.value.length > 1) {
    assistIngres.value.splice(index, 1);
  }
}

// 添加辅料
function addAssistIngredient() {
  // 检查辅料是否有未填写的输入框
  const hasEmptyInput = assistIngres.value.some(ingredient => ingredient.type === '' || ingredient.usage === '');
  if (hasEmptyInput) {
    alert('请先填写空的辅料输入框。'); 
    return; // 阻止添加新的输入框
  }
  assistIngres.value.push({ type: '', usage: '' });
}

// 绑定辅料输入
function bindInputAssistIngre(event) {
  const index = parseInt(event.target.dataset.index);
  const field = event.target.dataset.field;
  assistIngres.value[index][field] = event.target.value;
}

// 添加步骤
function addSteps() {
  // 检查步骤描述是否有未填写的输入框
  const hasEmptyStepDesc = steps.value.some(step => step.stepDesc === '');
  if (hasEmptyStepDesc) {
    alert('请先填写空的步骤描述。'); 
    return; // 阻止添加新的输入框
  }
  
  const newStepNum = steps.value.length + 1;
  steps.value.push({ stepNum: newStepNum, stepImg: '', stepDesc: '' });
}

// 绑定步骤描述输入
function bindStepDescInput(event) {
  const index = parseInt(event.target.dataset.index);
  steps.value[index].stepDesc = event.target.value;
}

function removeStep(index) {
  if (steps.value.length > 1) {
    steps.value.splice(index, 1);
  }
}

// 发布
async function uploadImages() {
  // 判空逻辑
  if (coverUrl.value.length === 0) {
    alert('请上传封面图片。');
    return;
  }
  if (cName.value.trim() === '') {
    alert('请填写菜名。');
    return;
  }
  if (description.value.trim() === '') {
    alert('请填写菜品描述。');
    return;
  }
  if (selectedKW.value === '') { 
    alert('请选择口味。');
    return;
  }
  if (selectedGY.value === '') {
    alert('请选择工艺。');
    return;
  }
  
  // 检查主料是否有未填写的输入框
  const hasEmptyMainInput = mainIngres.value.some(ingredient => ingredient.type.trim() === '' || ingredient.usage.trim() === '');
  if (hasEmptyMainInput) {
    alert('请确保所有主料的输入框都已填写。');
    return;
  }

  // 检查步骤描述是否有未填写的输入框
  const hasEmptyStepDesc = steps.value.some(step => step.stepDesc.trim() === '');
  if (hasEmptyStepDesc) {
    alert('请确保所有步骤描述都已填写。');
    return;
  }
  
// 上传封面图片
  const coverFile = coverUrl.value[0];
  console.log("封面文件:", coverFile);
  console.log(coverImageUrl.value)
  const originalCoverUrl = initCoverUrl.value; // 获取后端返回的原始封面图片 URL
  let finalCoverImageUrl = originalCoverUrl; // 默认使用原始 URL
  console.log("原始封面 URL:", originalCoverUrl);
  console.log("封面 URL:", finalCoverImageUrl);
  // 比较封面图片 URL
  if (coverFile && coverFile.tempFilePath !== originalCoverUrl) {
    const coverResponse = await uploadRecipeImages(coverFile);
    console.log("封面上传结果:", coverResponse);
    if (!coverResponse.data || coverResponse.data.length === 0) {
      alert('封面图片上传失败，请重试。');
      return;
    }
    finalCoverImageUrl = coverResponse.data[0]; // 更新为上传后返回的 URL
    console.log("更新后的封面 URL:", finalCoverImageUrl);
  }

// 上传步骤图片
for (let i = 0; i < steps.value.length; i++) {
    const step = steps.value[i];
    let stepFile;

    // 判断step.stepImg是否存在且是否包含特定的URL
    if (step.stepImg && step.stepImg.includes('https://eating-fun.oss-cn-beijing.aliyuncs.com/recipe/')) {
        // 如果包含特定URL，保留原图
        console.log(`步骤 ${i + 1} 的图片未更改, 保留URL: `, step.stepImg);
    } else {
        // 如果不包含，则需要进行上传处理
        if (step.stepImg) {
            if (typeof step.stepImg === 'string') {
                const response = await fetch(step.stepImg);
                const blob = await response.blob();
                const fileType = step.stepImg.split(';')[0].split(':')[1]; 
                stepFile = new File([blob], `step_${i}.${fileType.split('/')[1]}`, { type: fileType });
            } else if (step.stepImg instanceof File) {
                stepFile = step.stepImg; 
            }

            if (stepFile && stepFile.type) {
                const stepResponse = await uploadRecipeImages(stepFile);
                if (!stepResponse.data || stepResponse.data.length === 0) {
                    alert(`步骤 ${i + 1} 图片上传失败，请重试。`);
                    return;
                }
                step.stepImg = stepResponse.data[0]; // 更新为上传后的新 URL
            } else {
                console.error(`步骤 ${i + 1} 的图片无效: `, step.stepImg);
            }
        } else {
            step.stepImg = null; // 确保没有图片时设置为 null
        }
    }
}



  // 封装数据
  const ingredients = [
    mainIngres.value.map(ingredient => `${ingredient.type}~${ingredient.usage}`).join(','),
    assistIngres.value.map(ingredient => `${ingredient.type}~${ingredient.usage}`).join(',')
  ].filter(Boolean).join('|');

  const tasteMapping = {
    '五香': '1', '葱香': '2', '孜然': '3', '酸辣': '4', '甜辣': '5', '清淡': '6', '酱香': '7', '咖喱': '8', '更多': '9'
  };
  const techniqueMapping = {
    '炒': '1', '焖': '2', '炖': '3', '蒸': '4', '拌': '5', '烤': '6', '炸': '7', '卤': '8', '更多': '9'
  };

  const recipeData = {
    id: Number(props.recipeId), 
    name: cName.value,
    description: description.value,
    taste: tasteMapping[selectedKW.value],
    technique: techniqueMapping[selectedGY.value],
    ingredients: ingredients,
    coverUrl: finalCoverImageUrl,
    steps: steps.value.map((step, index) => ({
      number: index + 1,
      description: step.stepDesc,
      imageUrl: step.stepImg
    }))
  };
 
  console.log(recipeData);
  // 调用修改菜谱接口
  const response = await updateRecipe(recipeData);
  
  if (response.code === 200) {
      alert('菜谱更新成功！');
      // router.go(0); 
      //刷新页面
  } else {
      alert('更新失败，请重试。');
  }
  
  console.log('返回的数据:', response);
}

</script>


  
<style scoped>
.scroll-container {
  display: flex;
  width: 760px; 
  height: 650px; 
  background-color: #FFF2D0; 
  border-radius: 10px;
}

.left-section {
  width: 360px; 
  padding: 5px; 
  overflow: hidden;
}

.right-section {
  width: 370px; 
  padding: 5px; 
  overflow-y: auto; 
  overflow-x: hidden;
}

.scrollable-content {
  max-height: 100%; 
  margin: auto; 
}

.divider {
  width: 1px; 
  background-color: #ccc; 
}

.add-images, .input-section, .choose-section {
  margin-bottom: 0px; 
}

/* 口味和工艺按钮的样式 */
.button-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 8px; 
  margin: 0 5px;
  margin-bottom: 8px;
  margin-top: 10px;
  margin-left: 24px;
  width: 300px;
}

.button {
  height: 30px; 
  width: 70px;
  line-height: 30px; 
  text-align: center;
  border-radius: 25px;
  background-color: white;
  color: rgb(0, 0, 0);
  border: none; 
  cursor: pointer;
}

/* 上传图片部分 */
.add-images {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.cover-page {
  width: 100px; 
  height: 107px; 
  border-radius: 10px; 
  margin-left: 16px; 
  background-color: rgba(255,255,255,1);
  color: rgba(87, 87, 87, 1);
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 400;
  font-size: 12px; 
  line-height: 12px; 
  text-align: center;
}

.have-images {
  width: 102px; 
  height: 107px; 
  border-radius: 10px; 
}

.un-images {
  width: 97px; 
  height: 92px; 
}

.cover-page {
  display: inline-block;
  align-items: center;
  margin-right: 8px; 
}

/* 输入部分 */
.input-section {
  width: 350px; 
  margin-top: 18px; 
  margin-left: 20px; 
}

.input-cell-title {
  color: black;
  font-family: Microsoft YaHei;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px; 
  text-align: start;
}

.input-cell-after-title {
  width: 320px;
  height: auto;
  margin-top: 10px; 
  padding-top: 10px; 
  padding-bottom: 10px; 
  background-color: white;
  border-radius: 10px; 
}

.name-input {
  width: 300px; 
  height: 15px; 
  margin-left: 10px;
  margin-right: 10px;
  max-width: 560px; 
  border: none;
  outline: none;
}

.describe-input {
  height: 35px;
  width: 300px; 
  margin-left: 10px; 
  margin-right: 10px; 
  max-width: 560px; 
  border: none;
  outline: none;
}

/* 按钮 */
.choose-section {
  width: 450px; 
  margin-top: 28.8px; 
  margin-left: 20.8px; 
  align-items: center;
}

.selected {
  background-color: rgba(253, 213, 37, 1);  
  cursor: pointer;
}

/* 食材 */
.ingredients-section {
  width: 350px; 
  height: auto;
  margin-top: 50x; 
  margin-left: 10px; 
  display: flex;
  flex-direction: column;
}

.ingredients-title {
  display: flex;
  color: black;
  font-family: Microsoft YaHei;
  font-size: 16px; 
  font-weight: 400; 
  line-height: 18px; 
  text-align: start;
  margin-top: 14px;
}

.ingredients-add {
  height: 20px; 
  width: 20px; 
  margin-left: 10px; 
  cursor: pointer;
}

.ingredients-list {
  width: 100%; 
  display: flex;
  flex-direction: row;
  margin-left: 24px; 
  /* justify-content: space-between; */
}

.ingredients-type {
  width: 176px; 
  height: 30px; 
  border-radius: 25px; 
  align-items: center;
  justify-content: center;
  margin-top: 8px; 
  margin-bottom: 8px; 
  background-color: white;
}

.ingredients-usage {
  /* width: 73px;  */
  height: 30px; 
  border-radius: 25px; 
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px; 
  margin-left: 10px;
  background-color: white;
}

.input-type {
  margin-top: 6px; 
  margin-left: 5px; 
  margin-right: 5px; 
  outline: none;
  width: 150px;
  border: none;
  text-align: center;
}

.input-usage {
  margin-top: 6px;
  margin-left: 10px; 
  margin-right: 10px; 
  border: none;
  outline: none;
  width: 73px;
  text-align: center;
}

/* 制作步骤 */
.steps-section {
  width: 350px; 
  height: auto;
  margin-top: 20px; 
  margin-left: 10px; 
  display: flex;
  flex-direction: column;
}

.steps-title {
  display: flex;
  flex-direction: row;
}

.steps-title-text {
  margin-top: 12px; 
  color: black;
  font-family: Microsoft YaHei;
  font-size: 16px; 
  font-weight: 400; 
  line-height: 18px; 
  text-align: start;
  margin-top: 15px;
}

.steps-add {
  height: 20px; 
  width: 20px; 
  margin-left: 16px; 
  margin-top: 14px; 
  cursor: pointer;
}


.step {
  width: 350px; 
  height: 250px; 
  /* margin-top: -20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step-header {
  display: flex; 
  align-items: center;  
  justify-content: space-between; 
  margin-bottom: 0px;
  width: 330px;  
}

.step-body {
  background-color: white;
  width: 340px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.step-num {
  margin-top: 10px; 
  color: black;
  font-family: Microsoft YaHei;
  font-size: 14px; 
  font-weight: 700; 
  line-height: 18px; 
  text-align: start;
}

.step-upload-image {
  max-width: 323px; 
  max-height: 116px;
  margin-top: 10px;
}

.step-image {
  width: 323px; 
  height: 116px; 
  margin-top: 10px;
}

.step-input-detail {
  width: 320px; 
  height: 44px; 
  margin-left: 16px; 
  margin-right: 16px; 
  margin-bottom: 10px;
  margin-top: 18px; 
  border: none;
  outline: none;
}

/* 发布 */
.release {
  display: flex;
  bottom: 24px; 
  width: 300px; 
  height: 35px; 
  margin-top: 30px; 
  margin-bottom: 50px; 
  margin-left: 35px; 
  border-radius: 40px;
  background-color: rgba(151, 96, 34, 1);
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.release .release-text {
  display: flex;
  justify-content: center;
  width: 50px; 
  color: white;
  font-family: Microsoft YaHei;
  font-size: 20px; 
  font-weight: 400;
}

.delete-icon {
  width: 20px; 
  height: 20px; 
  margin-left: 10px; 
  margin-top: 15px;
  cursor: pointer; 
  z-index: 10000;
}
</style>
