<template>
  <div class="head-bar"></div>
  <div class="scroll-container">
    <!-- 封面 -->
    <div class="cover-img">
      <img :src="recipe.coverUrl" alt="封面图片" />
    </div>
    <!-- 标题 -->
    <div class="title-text">
      {{ recipe.name }}
    </div>
    <!-- 描述 -->
    <div class="description">
      {{ recipe.description }}
    </div>

    <!-- 口味和工艺 -->
    <div class="taste-box">
      <text class="second-text">口味</text>
      <div class="label-btn">{{ tasteName }}</div>
      <text class="second-text">工艺</text>
      <div class="label-btn">{{ techniqueName }}</div>
    </div>
    <!-- 材料 -->
    <div class="ingredients-box">
      <div class="main-ingredients">
        <text class="second-text">主料</text>
        <ul>
          <li
            v-for="ingredient in mainIngredients"
            :key="ingredient[0]"
            class="ingredient-item"
          >
            <div class="ingredient-name">{{ ingredient[0] }}</div>
            <div class="ingredient-amount">{{ ingredient[1] }}</div>
          </li>
        </ul>
      </div>
      <div class="aid-ingredients">
        <text class="second-text">辅料</text>
        <ul>
          <li
            v-for="ingredient in auxiliaryIngredients"
            :key="ingredient[0]"
            class="ingredient-item"
          >
            <div class="ingredient-name">{{ ingredient[0] }}</div>
            <div class="ingredient-amount">{{ ingredient[1] }}</div>
          </li>
        </ul>
      </div>
      <!-- 制作步骤 -->
      <div class="cooking-steps">
        <text class="second-text">制作步骤</text>
        <div
          class="steps-list"
          v-for="(step, index) in recipe.steps"
          :key="index"
        >
          <div class="step-number step-text">步骤{{ step.number }}：</div>
          <div class="step-description">{{ step.description }}</div>
          <div v-if="step.imageUrl" class="step-image">
            <img :src="step.imageUrl" :alt="`步骤${step.number}`" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecipeDetail } from "@/utils/api/recipe.js";

export default {
  props: {
    recipeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      recipe: null,
      tasteMap: {
        1: "五香",
        2: "葱香",
        3: "孜然",
        4: "酸辣",
        5: "甜辣",
        6: "清淡",
        7: "酱香",
        8: "咖喱",
        9: "更多",
      },
      techniqueMap: {
        1: "炒",
        2: "焖",
        3: "炖",
        4: "蒸",
        5: "拌",
        6: "烤",
        7: "炸",
        8: "卤",
        9: "更多",
      },
    };
  },
  computed: {
    tasteName() {
      return this.tasteMap[this.recipe.taste] || "未知口味";
    },
    techniqueName() {
      return this.techniqueMap[this.recipe.technique] || "未知工艺";
    },
    mainIngredients() {
      const parts = this.recipe.ingredients.split("|");
      const mainParts = parts[0].split(",");
      return mainParts.map((item) => item.split("~"));
    },
    auxiliaryIngredients() {
      const parts = this.recipe.ingredients.split("|");
      const auxiliaryParts = parts.length > 1 ? parts[1].split(",") : [];
      return auxiliaryParts.map((item) => item.split("~"));
    },
  },
  created() {
    this.recipe = {
      id: "",
      name: "",
      description: "",
      taste: "",
      technique: "",
      ingredients: "",
      coverUrl: "",
      steps: [],
    };
    this.fetchRecipeDetail();
  },
  methods: {
    async fetchRecipeDetail() {
      try {
        const response = await getRecipeDetail(this.recipeId);
        this.recipe = response.data;
        console.log(response.data);
      } catch (error) {
        console.error("Failed to fetch recipe detail:", error);
      }
    },
  },
};
</script>

<style scoped>
/* 字体样式 */
.title-text {
  font-family: Microsoft YaHei;
  font-size: 32px;
  font-weight: 700;
  line-height: 42.23px;
}

.second-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.68px;
  text-align: left;
}

.step-text {
  font-family: Microsoft YaHei;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.4px;
  text-align: left;
}

.head-bar {
  height: 100px;
}
.scroll-container {
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 640px;
  height: 500px;
  background-color: #ffffff;
  border-radius: 10px;
  overflow-y: auto;
  padding: 40px;
  text-align: left;
}

.scroll-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.cover-img,
.title-text,
.taste .description {
  width: 100%;
  margin: 10px 0;
}

.cover-img {
  margin-top: 3%;
  height: 360px;
  position: relative;
}

.cover-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.description {
  font-family: Microsoft YaHei;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.4px;
}

/* 口味部分 */
.taste-box {
  display: flex;
  gap: 60px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  font-family: Microsoft YaHei;
}

.label-btn {
  display: flex;
  height: 50px;
  width: 150px;
  margin-right: 30px;
  justify-content: center;
  align-items: center;
  background-color: #fed622;
  border-radius: 50px;
  font-size: 20px;
}

/* 食材部分 */
.ingredients-box {
  width: 97%;
}

.main-ingredients,
.aid-ingredients {
  text-align: left;
}

.main-ingredients,
.aid-ingredients {
  margin-bottom: 5px;
}

/* 食材列表项样式 */
.ingredients-box li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px dashed #ccc;
  margin: 0;
}

/* 食材名称和用量的容器 */
.ingredients-box li div {
  flex: 1;
  text-align: left;
}

/* 食材名称 */
.ingredients-box li .ingredient-name {
  text-align: left;
  margin-right: 10px;
}

/* 食材用量 */
.ingredients-box li .ingredient-amount {
  text-align: right;
}

/* 制作步骤 */
.steps-list {
  margin-bottom: 20px;
}
.step-number {
  font-weight: bold;
}
.step-description {
  margin-bottom: 10px;
}
.step-image img {
  max-width: 100%;
  height: auto;
}
</style>
