//1. 引入axios
import axios from "axios";

//2. 创建axios对象
const service = axios.create({
  // baseURL: "http://127.0.0.1:4523/m1/5190432-4856033-default",
  baseURL: "http://118.31.244.23:8989",
});

//3. 请求拦截器 ==> 前端给后端发送数据[ 没有到后端 ]
service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers["token"] = token;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//4.响应拦截器 ==> 后端给前端返回数据[ 前端到后端了 ]
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(new Error(error.response.data));
  }
);

export default service;
