<template>
    <div class="user-info">
        <div class="title-container">
            <div class="user-info-title">用户信息</div>
        </div>

        <div class="user-info-content">
            <img :src="userinfo.imageUrl" alt="img" />
            <div class="user-info-name">用户名：{{ userinfo.name }}</div>
            <button @click="handleDelete">删除</button>
        </div>

        <!-- 灰色遮罩层 -->
        <div v-if="showOverlay" class="overlay" @click="handleCancelDelete"></div>

        <delete-box v-if="showDeleteBox" :deleteType="1" :itemId="Number(userinfo.id)" @confirm="handleConfirmDelete"
            @cancel="handleCancelDelete"></delete-box>
    </div>
</template>

<script>
import DeleteBox from './delete-box.vue'; // 确保路径正确
//import { deleteUserById } from "@/utils/api/user.js"; // 验证删除接口的导入

export default {
    name: 'UserInfo',
    components: {
        DeleteBox
    },
    props: {
        userinfo: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            showDeleteBox: false, // 控制删除确认弹窗的显示
            showOverlay: false,    // 控制遮罩层的显示
        };
    },
    methods: {
        handleDelete() {
            this.showDeleteBox = true;
            this.showOverlay = true;  // 显示遮罩层
        },
        // handleConfirmDelete() {
        //     // 调用删除接口
        //     deleteUserById(this.userinfo.id).then(response => {
        //     if (response.code) {
        //         alert('用户删除成功');
        //         this.$emit('user-deleted', this.userinfo.id); // 触发用户删除事件
        //     } else {
        //         alert('删除失败: ' + response.msg);
        //     }
        //     }).catch(error => {
        //         console.error('删除用户失败:', error);
        //     });
        //     this.handleCancelDelete();
        // },
        handleConfirmDelete() {
            alert('用户删除成功');
            this.$emit('user-deleted', this.userinfo.id); // 通知父组件用户已被删除
            this.handleCancelDelete();
        },
        handleCancelDelete() {
            this.showDeleteBox = false;
            this.showOverlay = false; // 隐藏遮罩层
        }
    }
}
</script>

<style scoped>
.user-info {
    fill: #FFFFFF;
    border-radius: 25px;
    width: 306px;
    height: 120px;
    padding: 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
}

.title-container {
    height: 25px;
    padding: 5px 0;
    border-bottom: 1.2px solid #EDEDED;
}

.user-info-title {
    text-align: left;
    position: relative;
    top: 5px;
    left: 10px;
    color: #575757;
}

.user-info-content {
    justify-content: start;
    display: flex;
    position: relative;
}

img {
    padding-top: 12px;
    padding-left: 12px;
    width: 18%;
}

.user-info-name {
    font-size: 15px;
    padding: 5px 0;
    color: #575757;
    position: relative;
    top: 20px;
    left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.user-info-content button {
    padding: 4px 13px;
    background-color: #F14949;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    position: absolute;
    top: 55px;
    right: 10px;
    font-size: 11px;
}

/* 遮罩层样式 */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.user-info button:hover {
    background-color: #da190b;
}
</style>
