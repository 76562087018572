// api/cuisine.js
import request from "@/utils/request";

// 删除特定美食
export function deleteCuisine(id) {
  return request({
    url: `/admin/cuisine/${id}`, // integer
    method: "delete",
  });
}

// 查询美食列表
export function getCuisineList(pageNum = 1, pageSize = 6) {
  return request({
    url: "/admin/cuisine/getByName",
    method: "post",
    data: {
      pageNum, // integer
      pageSize, // integer
    },
  });
}

// 查询菜谱
export function getCuisine(pageNum, pageSize, cuisineName) {
  return request({
    url: "/admin/cuisine/getByName",
    method: "post",
    data: {
      pageNum, // integer
      pageSize, // integer
    },
    params: {
      cuisineName, // string
    },
  });
}

// 获取美食地图的省份列表
export function getProvinceList() {
  return request({
    url: "/admin/cuisine/province",
    method: "get",
  });
}

// 根据省份名字获取美食地图的数据
export function getCuisineByProvince(province) {
  return request({
    url: "/admin/cuisine/getByProvince",
    method: "get",
    params: {
      province: province, // 省份名称
    },
  });
}

// 获取点赞数前十的美食
export function getTopTenCuisines() {
  return request({
    url: "/admin/cuisine/topTen",
    method: "get",
  });
}
