// api/recipe.js
import request from "@/utils/request";

// 删除特定菜谱
export function deleteRecipe(id) {
  return request({
    url: `/admin/recipe/${Number(id)}`, // 接口地址， integer
    method: "delete",
  });
}

// 查询菜谱详情
export function getRecipeDetail(id) {
  return request({
    url: `/admin/recipe/${id}`, // 接口地址， integer
    method: "get",
  });
}

// 查询菜谱列表
export function getRecipeList(pageNum, pageSize, recipeName = "") {
  return request({
    url: "/admin/recipe/getByName",
    method: "post",
    data: {
      pageNum, // 页码
      pageSize, // 一页几个
      recipeName, // 菜谱名称（可选）
    },
  });
}

// 查询菜谱
export function getRecipe(recipeName, pageNum , pageSize) {
  return request({
    url: "/admin/recipe/getByName",
    method: "post",
    data: {
      pageNum, // integer
      pageSize, // integer
    },
    params: {
      recipeName, // string
    },
  });
}

// 上传图片列表 这个可能相关页面要前后端讨论一下->上传单张图片！！！！
// export function uploadRecipeImages(files) {
//   const formData = new FormData();
//   files.forEach((file) => {
//     formData.append("files", file);
//   });
//   return request({
//     url: "/admin/recipe/upload",
//     method: "post",
//     data: formData,
//     timeout: 50000,
//   });
// }
export function uploadRecipeImages(file) {
  const formData = new FormData();
  formData.append("file", file);

  return request({
    url: "/admin/recipe/upload",
    method: "post",
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 50000,
  });
}

// 新增菜谱
export function addRecipe(data) {
  return request({
    url: "/admin/recipe",
    method: "post",
    headers: {
      'Content-Type': 'application/json' // 确保添加这个请求头
    },
    data: JSON.stringify({
      name: data.name,
      description: data.description,
      taste: data.taste,
      technique: data.technique,
      ingredients: data.ingredients,
      coverUrl: data.coverUrl,
      steps: data.steps.map((step) => ({
        number: step.number,
        description: step.description,
        imageUrl: step.imageUrl,
      })),
    }),
  });
}

// 修改系统菜谱 要和后端讨论
export function updateRecipe(data) {
  return request({
    url: "/admin/recipe",
    method: "put",
    data: {
      id: data.id,
      name: data.name,
      description: data.description,
      taste: data.taste,
      technique: data.technique,
      ingredients: data.ingredients,
      coverUrl: data.coverUrl,
      steps: data.steps.map((step) => ({
        number: step.number,
        description: step.description,
        imageUrl: step.imageUrl || "", // 确保没有图片的步骤也有字段
      })),
    },
  });
}

// 获取点赞前十的菜谱
export function getTopTenRecipes() {
  return request({
    url: "/admin/recipe/topTen",
    method: "get",
  });
}
