<template>
  <div class="cuisine">
    <SidebarComponent />
    <!-- 还差删除逻辑为实现 -->
    <div class="content">
      <div class="search-bar">
        <div class="search-group">
          <div class="custom-input">
            <img src="@/assets/search.png" alt="Search" class="icon" />
            <input
              type="text"
              v-model="searchQuery"
              placeholder="请输入美食名称"
              @keyup.enter="handleSearch"
            />
          </div>
          <button class="search-button" @click="handleSearch">搜索</button>
        </div>
      </div>
      <div class="cuisine-container">
        <CuisineComponent
          v-for="cuisine in cuisines"
          :key="cuisine.id"
          :cuisineinfo="cuisine"
          @delete="handleDelete"
        />
      </div>
      <div class="pagination-container">
        <el-pagination
          @current-change="handlePageChange"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="totalCuisines"
          layout="prev, pager, next, jumper"
          :pager-count="5"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarComponent from "@/components/sidebar.vue";
import CuisineComponent from "@/components/cuisineinfo.vue";
import { getCuisine, getCuisineList } from "@/utils/api/cuisine.js";
import { ElPagination } from "element-plus";
export default {
  name: "CuisinePage",
  components: {
    SidebarComponent,
    CuisineComponent,
    ElPagination,
  },
  data() {
    return {
      searchQuery: "",
      currentPage: 1,
      pageSize: 6,
      totalCuisines: 0,
      cuisines: [],
    };
  },
  computed: {
    pagedRecipes() {
      const start = (this.currentPage - 1) * this.pageSize;
      return this.cuisines.slice(start, start + this.pageSize);
    },
  },
  methods: {
    async fetchRecipes() {
      try {
        let response;
        // 判断是否有搜索关键词，如果有，则调用搜索的接口
        if (this.searchQuery) {
          response = await getCuisine(
            this.currentPage,
            this.pageSize,
            this.searchQuery
          );
        } else {
          response = await getCuisineList(this.currentPage, this.pageSize); // 带分页参数
        }

        // 带分页参数和可选的美食名称参数
        console.log("获取美食的请求参数:", this.searchQuery);
        console.log("获取美食数据成功:", response);
        if (response.data && response.data.rows.length > 0) {
          this.totalCuisines = Number(response.data.total);
          this.cuisines = response.data.rows;
        } else {
          this.cuisines = [];
          this.totalCuisines = 0;
        }
      } catch (error) {
        console.error("获取美食数据失败:", error);
        this.cuisines = [];
        this.totalCuisines = 0;
      }
    },
    handleSearch() {
      console.log("Searching for:", this.searchQuery);
      this.currentPage = 1; // 重置当前页码为第一页
      this.fetchRecipes(); // 重新获取数据以应用搜索过滤
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchRecipes();
    },
    async handleDelete(code) {
      // 将cuisineId转换为整数类型
      try {
        // if (code === 200) {
        if (code) {
          console.log("被删除的美食号：" + code);
          // 测试阶段使用
          // 提示用户删除成功
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.fetchRecipes(); // 重新获取数据
        }
      } catch (error) {
        // 提示用户删除失败
        this.$message({
          type: "error",
          message: "删除失败: " + error.message,
        });
        console.error("删除美食失败:", error);
      }
    },
  },
  mounted() {
    console.log("组件挂载，开始获取美食数据...");
    this.fetchRecipes();
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  z-index: 1000;
}

.add-recipe {
  position: fixed;
  margin-top: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* 居中 */
  z-index: 1001;
  background: transparent;
  padding: 20px;
  border-radius: 8px;
}

.close-icon {
  width: 30px;
  height: 30px;
}

.close-button {
  position: absolute; /* 绝对定位 */
  top: -15px; /* 距离顶部10px */
  right: -10px; /* 距离右边10px */
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.delete-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
}

.cuisine {
  display: flex;
  height: 100vh;
  background-color: #f7f7f7;
}

.content {
  flex: 1;
  padding: 10px;
  background-color: #f7f7f7;
  height: 100vh;
  margin-top: 115px;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 200px;
}

.search-group {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.custom-input {
  display: flex;
  align-items: center;
  height: 22px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px;
  flex-grow: 1;
  width: 300px;
}

.custom-input input {
  border: none;
  outline: none;
  flex-grow: 1;
}

.search-button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #ffd400;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.add-button {
  margin-right: 10px;
  padding: 8px 16px;
  background-color: #ffd400;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.cuisine-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-left: 200px;
  row-gap: 30px;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
</style>
