<template>
  <div class="recipe">
    <SidebarComponent />

    <div class="content">
      <div v-if="showAddRecipe" class="overlay">
        <div class="add-recipe">
          <button class="close-button" @click="showAddRecipe = false">
            <img src="@/assets/close1.png" alt="关闭" class="close-icon"/>
          </button>
          <AddRecipe @close="showAddRecipe = false" />
        </div>
      </div>

      <div v-else>
        <div class="search-bar">
          <div class="search-group">
            <div class="custom-input">
              <img src="@/assets/search.png" alt="Search" class="icon" />
              <input
                type="text"
                v-model="searchQuery"
                placeholder="请输入菜谱名称"
              />
            </div>
            <button class="search-button" @click="handleSearch">搜索</button>
          </div>
          <button class="add-button" @click="showAddRecipe = true">增加菜谱</button>
        </div>
        <div class="recipe-container">
          <RecipeComponent
            v-for="recipe in recipes"
            :key="recipe.id"
            :recipe="recipe"
            @delete="handleDeleteRecipe" 
          />
        </div>
        <div class="pagination-container">
          <el-pagination
            @current-change="handlePageChange"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="totalRecipes"
            layout="prev, pager, next, jumper"
            :pager-count="5"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarComponent from "@/components/sidebar.vue";   
import RecipeComponent from "@/components/recipe.vue";   
import AddRecipe from "@/components/AddRecipe.vue";   
import { getRecipeList, getRecipe } from "@/utils/api/recipe.js"; 
import { ElPagination } from 'element-plus';   

export default {  
  name: "RecipePage",  
  components: {  
    SidebarComponent,  
    RecipeComponent,  
    ElPagination,
    AddRecipe
  },  
  data() {  
    return {  
      searchQuery: '',  
      currentPage: 1,  
      pageSize: 6,  
      totalRecipes: 0,  
      showAddRecipe: false,  
      recipes: [],
    };  
  },   
  methods: {  
    async fetchRecipes() {  
      try {  
        let response;
        // 判断是否有搜索关键词，如果有，则调用搜索的接口
        if (this.searchQuery) {
          response = await getRecipe(this.searchQuery, this.currentPage, this.pageSize);
        } else {
          response = await getRecipeList(this.currentPage, this.pageSize); // 带分页参数  
        }
        
        console.log("获取菜谱的请求参数:", {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          recipeName: this.searchQuery || null,
        });
        console.log("获取菜谱数据成功:", response);  

        if (response.data && response.data.rows.length > 0) {  
          this.recipes = response.data.rows; 
          this.totalRecipes = Number(response.data.total);  
        } else {  
          this.recipes = []; 
          this.totalRecipes = 0; 
        }  
      } catch (error) {  
        console.error("获取菜谱数据失败:", error);  
        this.recipes = []; 
        this.totalRecipes = 0; 
      }  
    },
    handleSearch() {  
      console.log('正在搜索:', this.searchQuery);
      this.currentPage = 1; // 重置到第一页
      this.fetchRecipes(); // 重新获取菜谱数据
    },
    handlePageChange(page) {  
      this.currentPage = page;  
      this.fetchRecipes(); 
    },
    handleDeleteRecipe(id) {
      console.log('删除菜谱:', id);
      this.recipes = this.recipes.filter(recipe => recipe.id !== id);
      this.totalRecipes--;
      this.fetchRecipes();
    }
  },  
  mounted() {  
    console.log("组件挂载，开始获取菜谱数据...");  
    this.fetchRecipes();  
  }  
};  
</script>


<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  z-index: 1000; 
}

.add-recipe {
  position: fixed;
  margin-top: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* 居中 */
  z-index: 1001; 
  background: transparent;
  padding: 20px;
  border-radius: 8px;
}

.close-icon {
  width: 30px; 
  height: 30px; 
}

.close-button {
  position: absolute; /* 绝对定位 */
  top: -15px; /* 距离顶部10px */
  right: -10px; /* 距离右边10px */
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.delete-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  z-index: 1001; 
}

.recipe {
  display: flex;
  height: 100vh;
  background-color: #f7f7f7; 
}

.content {
  flex: 1;
  padding: 10px;
  background-color: #f7f7f7; 
  height: 100vh; 
  margin-top: 115px;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  margin-bottom: 20px;
  margin-left: 200px;
}

.search-group {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 5px;
  width: 20px; 
  height: 20px; 
}

.custom-input {
  display: flex;
  align-items: center;
  height: 22px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px;
  flex-grow: 1;
  width: 300px;
}

.custom-input input {
  border: none;
  outline: none;
  flex-grow: 1;
}

.search-button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #ffd400;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.add-button {
  margin-right: 10px;
  padding: 8px 16px;
  background-color: #ffd400;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.recipe-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 15px; 
  margin-left: 200px;
  row-gap: 30px;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
</style>
